import React, { useState, useRef, useEffect } from "react";
import Logo from "../assets/logo/Logo.png";
import ProfileIcon from "../assets/icons/ProfileIcon.png"; // Import profile icon
import { useNavigate } from "react-router-dom";
import hamsvg from "../Images/hamsvg.svg";
import crosspng from "../Images/cross.png";
import { useAppContext } from "../appContext";
import { useLocation } from "react-router-dom";
import LinkItem from "./linkItem";
import { navItems, navItemsHome } from "../data";

function Header() {
  const navigate = useNavigate();
  const { setAuthUser, setIsUserLoggedIn, isUserLoggedIn } = useAppContext();
  const [isMenuActive, setisMenuActive] = useState(false);
  const [isProfileMenuActive, setIsProfileMenuActive] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  const profileMenuRef = useRef(null);

  function handleLink(link) {
    navigate(link);
  }

  const handleLogout = () => {
    try {
      localStorage.removeItem("authToken");
      localStorage.removeItem("authUser");
      setIsUserLoggedIn(false);
      setAuthUser(null);
      
      navigate("/login");
    } catch (err) {
      console.log(err);
    }
  };

  const handleScroll = (name) => {
    if (path === "/") {
      const element = document.getElementById(name);
      const navbarHeight = document.querySelector(".nav").offsetHeight;
      if (element) {
        const offsetTop = element.offsetTop - navbarHeight;
        window.scrollTo({ top: offsetTop, behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
        setIsProfileMenuActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileMenuRef]);

  const excludeHeader = ["/Vriksh", "/DeCarbonn"];
  if (excludeHeader.includes(path)) {
    return <div />;
  }

  return (
    <>
      <a
        href="https://changemakers.com/en/challenge/ambitionaccelerator"
        className="h-10 bg-[#1E3A2B] flex items-center justify-center text-lg text-white w-full hover:no-underline hover:scale-105 transition-transform duration-300"
        target="_blank"
        rel="noopener noreferrer"
      >
        We Got into Ambition Accelerator 🚀
      </a>
      <div className="cursor-default sticky nav w-auto top-0 md:flex-col flex md:justify-around justify-between items-center mt-[20px] text-[#1E3A2B] bg-[#ffffff] h-[80px] z-10">
        <div className="md:ml-2 ml-3 flex order-first">
        <a href="/"
        className="flex items-center">
          <img
            src={Logo}
            alt="Logo"
            className="md:w-[40px] w-[25px] md:h-[40px] h-[25px]"
          />
          <div className="font-[Gilroy-Light] md:text-[30px] text-[20px] md:ml-1 ml-1">
            Maiti Labs
          </div>
          </a>
        </div>
        <div
          className="md:hidden block md:w-[34px] w-[25px] order-last ml-[40px] md:h-[42px] h-[30px] mr-3"
          onClick={() => {
            setisMenuActive(!isMenuActive);
          }}
        >
          <img src={isMenuActive ? crosspng : hamsvg} alt="menu" />
        </div>

        <div>
          <div className="md:flex flex-row hidden mx-auto my-auto" id="navitem">
            {path === "/"
              ? navItemsHome.map((item, index) => {
                  return (
                    <LinkItem
                      key={index}
                      name={item.name}
                      handleFunc={(link) => {
                        link === "team" || link === "Vriksh"
                          ? handleLink(link)
                          : handleScroll(link);
                      }}
                      To={item.to}
                    ></LinkItem>
                  );
                })
              : navItems.map((item, index) => {
                  return (
                    <LinkItem
                      key={index}
                      name={item.name}
                      handleFunc={handleLink}
                      To={item.to}
                    ></LinkItem>
                  );
                })}
          </div>
          <div
            id="google_translate_element"
            className="w-full h-auto md:block order-2 rounded-lg mx-auto placeholder-opacity-75 focus:outline-none p-[8px] text-base mt-[-10px]"
          ></div>
        </div>
        <div className="md:flex hidden text-center">
          {isUserLoggedIn ? (
            <div className="relative" ref={profileMenuRef}>
              <img
                src={ProfileIcon}
                alt="Profile"
                className="w-[40px] h-[40px] rounded-full cursor-pointer"
                onClick={() => setIsProfileMenuActive(!isProfileMenuActive)}
              />
              {isProfileMenuActive && (
                <div className="absolute right-0 mt-2 w-[150px] bg-white border rounded shadow-lg">
                  <button
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => navigate("/dashboard")}
                  >
                    View Dashboard
                  </button>
                  <button
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          ) : (
            <DrawOutlineButton>
              <LinkItem name={"Login"} handleFunc={handleLink} To={"/login"} />
            </DrawOutlineButton>
          )}
        </div>
      </div>

      <div
        className={
          isMenuActive
            ? "md:hidden flex flex-col sticky top-[70px] bg-white z-50 px-2"
            : "hidden"
        }
      >
        {navItems.map((item, index) => (
          <LinkItem
            key={index}
            name={item.name}
            handleFunc={handleLink}
            To={item.to}
          />
        ))}

        <div className="text-center mr-9 relative w-[9%] h-[45px] md:ml-0 ml-4">
          {isUserLoggedIn ? (
            <>
              <button
                className="my-[3px] ml-4 mx-auto text-center"
                onClick={() => navigate("/dashboard")}
              >
                View Dashboard
              </button>
              <button
                className="my-[3px] ml-4 mx-auto text-center"
                onClick={handleLogout}
              >
                Logout
              </button>
            </>
          ) : (
            <div
              className="my-auto mx-auto cursor-pointer hover:shadow-xl hover:text-[#496c62] rounded-sm"
              onClick={() => {
                handleLink("login");
              }}
            >
              Login
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const DrawOutlineButton = ({ children, ...rest }) => {
  return (
    <button
      {...rest}
      className="group relative font-medium cursor-pointer w-[150px] h-[40px] delay-75 rounded-full bg-[#1e3a2b] hover:bg-slate-100/50 hover:shadow-md hover:shadow-emerald-800/50 text-slate-100 transition-colors duration-[300ms] hover:text-[#1e3a2b]"
    >
      <span>{children}</span>
    </button>
  );
};

export default Header;
