import React,{useEffect} from "react";
import cr7 from "../Images/cr7.jpg";
import { Helmet } from "react-helmet";


export default function About(){

  useEffect(() => {
    window.scrollTo( {top: 0,behavior: "instant"} )
  }, []);

    return(
        <section>
          <Helmet>
            <title>Maiti Labs - About</title>
            <meta name="description" content="Learn more about Maiti Labs, a platform dedicated to raising climate awareness through multilingual content, tech innovation, and sustainable initiatives." />
            <link rel="canonical" href="https://www.maitilabs.org/about" />
            <script type="application/ld+json">
              {`
                {
                  "@context": "https://schema.org",
                  "@type": "WebPage",
                  "name": "About Maiti Labs",
                  "url": "https://www.maitilabs.org/about",
                  "description": "Learn more about Maiti Labs, a platform dedicated to raising climate awareness through multilingual content, tech innovation, and sustainable initiatives.",
                  "mainEntity": {
                    "@type": "Organization",
                    "name": "Maiti Labs"
                  }
                }
              `}
            </script>
          </Helmet>
          <div>
              <h1 className="text-white text-center mt-[30px] md:text-[60px] text-[32px]  font-[Gilroy-Bold] bg-[#1E3A2B]">
                ABOUT
              </h1>
          </div>
          <div className="flex justify-center items-center md:p-[75px] p-8 ">
            <p className="text-[#1E3A2B] md:w-[75%] md:text-[20px] text-[12px] text-justify">
            Welcome to Maiti Labs, where passion meets purpose in our relentless pursuit of global climate awareness and sustainable action. 
            Founded with a mission to empower communities with knowledge about climate change, we have curated a dynamic platform that 
            transcends linguistic barriers. At the core of our endeavor is the Maiti Labs Awareness Portal, a gateway to a wealth of climate
            information available in multiple languages. We believe that by making climate-related articles accessible to diverse audiences,
            we can spark meaningful conversations and drive collective action.
            </p>
          </div>
          <div className="pt-[30px] pb-[70px] md:flex block">
            <img src={cr7} alt="Climate About Page" className="xl:w-[689px] xl:h-[1246px] lg:h-[1018px] lg:w-[489px] md:w-[369px] md:h-[726px] md:flex hidden "/>
            <img src={cr7} alt="Climate About Page" className="md:hidden w-[90%] h-[240px] flex mx-auto object-cover"/>
              <div className="md:w-[50%] w-[auto] md:mx-auto mx-auto p-8 my-auto block">
                <h2 className="text-[#1E3A2B] xl:text-[42px] lg:text-[32px] md:text-[20px] text-[18px] font-[Gilroy-XBold] xl:text-left text-left">
                  Inspiring Action Through Content
                </h2>
                <p className="mt-2 font-[Gilroy-Light] xl:font-medium font-semibold xl:text-[22px] lg:text-[18px] md:text-[14px] md:font-semibold text-[12px] xl:text-justify text-justify">
                  Our commitment extends beyond information dissemination. Through engaging content on Climate Startups and Climate News,
                  presented in captivating videos, we aim to create a ripple effect of awareness. Our content strategy is anchored by a
                  distinct voice, ensuring that our message resonates with individuals from all walks of life.
                </p>

                <h2 className="mt-5 text-[#1E3A2B] xl:text-[42px] lg:text-[32px] md:text-[20px] text-[18px] font-[Gilroy-XBold] xl:text-left text-left">
                  Riving Efficiency with Technology
                </h2>
                <p className="mt-2 font-[Gilroy-Light] xl:font-medium font-semibold xl:text-[22px] lg:text-[18px] md:text-[14px] md:font-semibold text-[12px] xl:text-justify text-justify">
                  In tandem with our content initiatives, our Tech division pioneers innovation in the realm of artificial intelligence and machine learning.
                  The development of a cutting-edge AI model for data compression reflects our dedication to efficiency and sustainability.
                  By optimizing image storage, we actively contribute to energy conservation, aligning technology with climate action.
                </p>

                <h2 className="mt-5 text-[#1E3A2B] xl:text-[42px] lg:text-[32px] md:text-[20px] text-[18px] font-[Gilroy-XBold] xl:text-left text-left">
                  Envisioning a Sustainable Future
                </h2>
                <p className="mt-2 font-[Gilroy-Light] xl:font-medium font-semibold xl:text-[22px] lg:text-[18px] md:text-[14px] md:font-semibold text-[12px] xl:text-justify text-justify">
                  Join us on this transformative journey as we leverage technology, content creation, and a shared commitment to make a positive impact on our planet.
                  At Maiti Labs, we envision a world where knowledge drives change, and every individual is empowered to be a steward of our environment.
                  Together, let's forge a sustainable future.
                </p>
              </div>
          </div>
        </section>
    )
}