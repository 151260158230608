import { Vhome } from "./Vriksh/Vhome";
import { AboutPage } from "./Vriksh/AboutPage";
import { Subs } from "./Vriksh/Subs";
import { Perks } from "./Vriksh/Perks";
import { Footer } from "./Vriksh/Footer";
import { VrikshNavBar } from "./Vriksh/VrikshNavBar";
import { Helmet } from "react-helmet";

const Vriksh = () => {
  return (
  <div>
      <Helmet>
        <title>Maiti Labs - Vriksh</title>
        <meta name="description" content="The Vriksh initiative by Maiti Labs encourages people to plant trees to combat climate change and improve the environment." />
        <link rel="canonical" href="https://www.maitilabs.org/Vriksh" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Vriksh",
              "url": "https://www.maitilabs.org/Vriksh",
              "description": "The Vriksh initiative by Maiti Labs encourages people to plant trees to combat climate change and improve the environment.",
              "mainEntity": {
                "@type": "Organization",
                "name": "Maiti Labs",
              }
            }
          `}
        </script>
      </Helmet>
      <VrikshNavBar/>
      <Vhome />
      <AboutPage />
      <Subs />
      <Perks />
      <Footer />
    </div>
  );
}

export default Vriksh;
