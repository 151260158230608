import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import "./index.css";
import App from "./App";
import { AppProvider } from "./appContext";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <React.StrictMode>
      <AppProvider>
        <QueryClientProvider client={queryClient}>
          <App />
          {/* <ReactQueryDevtools initialIsOpen/> */}
        </QueryClientProvider>
      </AppProvider>
    </React.StrictMode>
  </React.StrictMode>
);
