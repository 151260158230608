import React from "react";
import { RxInstagramLogo } from "react-icons/rx";
import { FaLinkedin } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import Logo from "../assets/logo/Logo.png";

const GreenBitFooter = () => {
  return (
    <>
      <footer className="bg-[#303B36] p-8">
        <div className="mt-[80px] mx-auto lg:w-[80%] lg:flex block mb-[80px] gap-24 ">
          <div className="lg:h-[503px] lg:w-[50%] w-[100%] lg:mx-[52px] mx-auto lg:mb-[10px] mb-[150px]">
            <div className="flex-col mx-auto lg:w-[80%] mt-[20px] items-center  lg:h-[124px] lg:text-[30px] text-[20px]  flex  justify-center leading-[140.63%]">
              <p className="font-[Gilroy-Light] text-4xl font-bold text-white text-left mb-3">
                {" "}
                Let's Connect !{" "}
              </p>
              <p className="font-[Gilroy-Light] text-xl text-white text-center  ">
                We welcome inquiries from businesses coperations, and academic
                institutions for potential collaborations and partnerships
                Contact us to ecplore exciting opportunities
              </p>
            </div>

            <div className="w-[85%] h-[290px] mt-[49px]  mx-auto">
              <div className="lg:flex block mb-4">
                <input
                  type="text"
                  className="p-2  lg:w-[50%] w-[100%] border border-gray-300 bg-[#303B36] rounded mr-2  lg:mt-0 mt-2"
                  placeholder="Name"
                />
                <input
                  type="text"
                  className="p-2 border lg:w-[50%] w-[100%] border-gray-300 bg-[#303B36] rounded  lg:mt-0 mt-2"
                  placeholder="Email"
                />
              </div>
              <textarea
                className="p-2 border  w-[100%] h-[180px] border-gray-300 bg-[#303B36] rounded resize-none"
                placeholder="Message"
              ></textarea>
              <button className="h-[15%] w-[100%] text-white  bg-black rounded-md">
                Send
              </button>
            </div>
          </div>

          <div className="flex flex-wrap xl:block gap-5 xl:gap-5 justify-center mr-[13px] lg:w-[30%] w-[80%] lg:ml-[106px] mx-auto my-[46px]">
            {/* email div */}
            <div className="mt-[10px] mb-[23px] w-80% md:h-100px mx-auto font-Gilroy-light leading-1.2em text-left text-white order-2 lg:order-1">
              EMAIL
              <div className="mt-[23px] text-left text-base lg:ml-[32px] ">
                <a
                  href="mailto:labsmaiti@gmail.com"
                  className="hover:text-[#8f9b95] "
                >
                  <IoMdMail className="inline mr-2" /> Mail
                </a>
              </div>
            </div>
            {/* social div */}
            <div className="mt-[23px] mb-[23px] w-80% mx-auto font-Gilroy-light leading-1.2em text-left text-white order-3 lg:order-2">
              SOCIAL
              <div className="mt-[24px] text-base text-left lg:ml-[30px] ">
                <ul>
                  <li>
                    {" "}
                    <a
                      className="block mt-24px hover:text-[#8f9b95] mt-[23px]"
                      href="https://www.instagram.com/maitilabs?igsh=MWF0c2J3eXdvNmFhMQ=="
                    >
                      <RxInstagramLogo className="inline mr-2 " />
                      Instagram
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a
                      className="block mt-24px hover:text-[#8f9b95] mt-[12px]"
                      href="https://www.linkedin.com/company/maitilabs/"
                    >
                      <FaLinkedin className="inline mr-2" />
                      Linkedin
                    </a>
                  </li>
                  <li>
                    <a
                      className="block mt-24px hover:text-[#8f9b95] mt-[12px]"
                      href="/"
                    >
                      <FaTwitter className="inline mr-2" />
                      Twitter
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* powered by div */}
            <div className="lg:mt-[40px] mb-[23px] w-[100%]  lg:w-[80%]  items-center justify-center mx-auto font-[Gilroy-light]   font-bold leading-[1.2em] text-left text-white order-1 lg:order-3 ">
              Powered By:
              <div className=" lg:ml-[50px] flex order-first items-center">
                <img
                  src={Logo}
                  alt=""
                  className="mt-[20px] mr-[15px] w-[50px] h-[50px]   border-1 "
                />
                <div className="mt-[23px] w-[80%]  font-[Gilroy-Light] lg:text-[30px] text-[20px] font-bold lg:ml-1 ml-1">
                  MAITI LABS
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default GreenBitFooter;
