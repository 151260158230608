import logo from "../assets/logo.png";
// import { Container, Carousel } from "react-bootstrap";
import GTrees from "../../Images/GTrees.png";
import GBT from "../../Images/GBT.jpg";
import gt2 from "../../Images/gt2.jpeg";
import purva from "../../Images/purva.png";
import nativegreen from "../../Images/nativegreen.png";
import neer from "../../Images/neer.png";
import gitrust from "../../Images/gitrust.png";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import useScreenSize from "../../hooks/useScreenSize";

const partners = [
  {
    src: GTrees,
    alt: "First partner",
    link: "https://www.grow-trees.com/",
  },
  {
    src: GBT,
    alt: "Second partner",
    link: "https://growbilliontrees.com/",
  },
  {
    src: gt2,
    alt: "Third partner",
    link: "https://www.facebook.com/greenleafngo.org",
  },
  {
    src: purva,
    alt: "Fourth Partner",
    link: "https://www.purvafoundation.org/index.html",
  },
  {
    src: nativegreen,
    alt: "Fifth Partner",
    link: "https://nativegreenfoundation.org/",
  },
  {
    src: neer,
    alt: "Sixth Partner",
    link: "http://www.theneerfoundation.org/index.aspx",
  },
  {
    src: gitrust,
    alt: "Seventh Partner",
    link: "https://gitrust.org/"
  },
];

export const Footer = () => {
  const [email, setEmail] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [disable, setDisable] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { isMobile, isTablet } = useScreenSize();
  const [fadeClass, setFadeClass] = useState('fade-enter');

  const getLogosPerSlide = useCallback(() => {
    if (isMobile) return 1;
    if (isTablet) return 3;
    return 4; // desktop
  },[isMobile, isTablet]);

  const getPartnersToShow = () => {
    const logosPerSlide = getLogosPerSlide();
    const startIndex = currentIndex * logosPerSlide;
  return partners.slice(startIndex, startIndex + logosPerSlide);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setFadeClass('fade-exit');
      setTimeout(() => {
        setCurrentIndex((prevIndex) =>
          (prevIndex + 1) % Math.ceil(partners.length / getLogosPerSlide())
        );
        setFadeClass('fade-enter');
      }, 1000);
    }, 4000); // Change slide every 4 seconds

    return () => clearInterval(timer);
  }, [getLogosPerSlide]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try{
      setDisable(true);
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/subscribe`, {email});
      console.log(res.data);
      setEmail('');
      setDisable(false);
      setShowConfirmation(true);
    } catch (err){
      console.error(err);
    }
  }
  return (
    <>
      <div
        id="partners"
        className="cursor-default bg-[#D9A673] pt-[50px] pb-[50px] w-full mt-[50px]"
      >
        <p className="mx-auto w-fit text-center font-[Gilroy-XBold] md:text-[50px] text-[40px] tracking-[-2.1px] leading-[1.2em] mb-10">
          Our Partners
        </p>

        <div className="flex justify-evenly items-center space-x-6">
          {getPartnersToShow().map((partner, index) => (
            <div
              key={index}
              className={`text-center transition-transform transform hover:scale-105 hover:shadow-lg flex-none m-2 ${fadeClass}`}
            >
              <a href={partner.link} target="_blank" rel="noopener noreferrer">
                <img
                  className="md:w-[200px] md:h-[150px] w-[150px] h-[100px] rounded-[15px] bg-cover bg-[#EFE6DC] transition-all object-contain"
                  src={partner.src}
                  alt={partner.alt}
                />
              </a>
            </div>
          ))}
        </div>

        <div className="flex justify-center mt-4">
        {Array.from({ length: Math.ceil(partners.length / getLogosPerSlide()) }).map((_, index) => (
            <button
              key={index}
              className={`h-3 w-3 rounded-full mx-1 ${
                currentIndex === index ? 'bg-gray-800' : 'bg-gray-400'
              }`}
              onClick={() => {
                setCurrentIndex(index);
              }}
            />
          ))}
        </div>
      </div>


      <div className="bg-custom-green4 text-white px-[50px] mm:px-[120px] font-jostSemiBold">
        <h2 className="text-xl mm:text-[25px] md:text-3xl lgg:text-[36px] xl:text-4xl xll:text-5xl pt-[40px]">
          Our Objective
        </h2>
        <div className="flex pt-[20px] pb-[60px] space-x-[10px] mm:space-x-[20px]">
          <h2 className="text-[20px] mm:text-[32px] md:text-[40px] cs:text-[55px] lg:text-[60px] lgg:text-[72px] xl:text-[80px] xll:text-[96px]  ">
            ONE MILLION TREES{" "}
          </h2>
          <h2 className="text-[10px] mm:text-[14px] md:text-[15px] cs:text-[20px] lg:text-[20px] lgg:text-[25px] xl:text-[30px] xll:text-[40px] mt-[10px] mm:mt-5 md:mt-8 cs:mt-9 lg:mt-10 xl:mt-12 xll:mt-10 ">
            BY
          </h2>
          <h2 className="text-[20px] mm:text-[32px] md:text-[40px] cs:text-[55px] lg:text-[60px] lgg:text-[72px] xl:text-[80px] xll:text-[96px]  ">
            {" "}
            2030
          </h2>
        </div>
      </div>
      <footer className="relative flex flex-col bg-footer-image bg-cover bg-center w-auto h-auto  py-[8px]  px-10">
        <div className="relative flex ml-[-50px] mm:ml-0">
          <div>
            <img
              src={logo}
              alt="logo"
              className="w-[120px] h-[120px] cs:w-[150px] cs:h-[150px] xl:w-[191px] xl:h-[191px]"
            />
          </div>
          <div>
            <h2 className="p-[8px] my-[32px] mx-[-35px] cs:my-10 cs:mx-[-45px] xl:my-14 xl:mx-[-55px] text-custom-green4 font-jostSemiBold text-4xl cs:text-5xl xl:text-6xl">
              Vriksh
            </h2>
          </div>
        </div>
        <div className="flex flex-col-reverse  items-center mm:m-0 mm:flex-row justify-evenly">
          <div className="flex text-center gap-[40px] xl:gap-20">
            <div>
              <h2 className="text-3xl cs:text-4xl xl:text-5xl font-jostSemiBold mb-[24px] cs:mb-8 xl:mb-10 whitespace-nowrap">
                Our Social
              </h2>
              <a
                href="https://www.instagram.com/maitilabs?igsh=MXEzbzkxbGJpdWdybw=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <h2 className="text-2xl cs:text-3xl xl:text-4xl font-jostSemiBold text-white mb-[24px] cs:mb-8 xl:mb-10">
                  Instagram
                </h2>
              </a>

              <a
                href="https://x.com/MaitiLabs?t=t0BTUrSX61h-gBoopo700A&s=08"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h2 className="text-2xl cs:text-3xl xl:text-4xl font-jostSemiBold text-white mb-[24px] cs:mb-8 xl:mb-10">
                  Twitter
                </h2>
              </a>
              <a
                href="https://www.linkedin.com/company/maitilabs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h2 className="text-2xl cs:text-3xl xl:text-4xl font-jostSemiBold text-white mb-[24px] cs:mb-8 xl:mb-10">
                  LinkedIn
                </h2>
              </a>
            </div>
            <div>
              <h2 className="text-3xl cs:text-4xl xl:text-5xl font-jostSemiBold mb-[24px] cs:mb-8 xl:mb-10">
                Discover
              </h2>
              <Link to="/">
                <h2 className="text-2xl cs:text-3xl xl:text-4xl font-jostSemiBold text-white mb-[24px] cs:mb-8 xl:mb-10">
                  Home
                </h2>
              </Link>
              <h2
                className="text-2xl cursor-pointer cs:text-3xl xl:text-4xl font-jostSemiBold text-white mb-[24px] cs:mb-8 xl:mb-10"
                onClick={() => {
                  const navPartners = document.querySelector(
                    'a[href="#partners"]'
                  );
                  navPartners.click();
                }}
              >
                Partners
              </h2>
              <Link to="/about">
                <h2 className="text-2xl cs:text-3xl xl:text-4xl font-jostSemiBold text-white mb-[24px] cs:mb-8 xl:mb-10">
                  About
                </h2>
              </Link>
            </div>
          </div>
          <form 
            className="pb-10 mm:pb-0 mm:py-[8px] mx-[40px] md:mx-0 lg:mx-16 md:block flex flex-col items-center "
            onSubmit={handleSubmit}
          >
            <h2 className="text-3xl cs:text-4xl xl:text-5xl px-[16px] text-center mm:text-left font-jostSemiBold py-[10px] mm:py-[30px] cs:py-[40px] xl:py-[50px]  ">
              Subscribe To <br /> Hear From Us
            </h2>
            <input
              type="email"
              required
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="w-[20rem] xl:w-[28rem] xl:h-[3.6rem] pl-[16px] py-[8px] mx-[40px] sm:mx-0 md:pl-5 text-2xl rounded-2xl border-[2px] border-[#1e3a2b] outline-none"
            />
            <button
              type="submit"
              disabled={disable}
              className="mt-4 sm:mt-0 sm:ml-4 px-8 py-2 bg-custom-green4 text-white text-2xl cs:text-3xl xl:text-4xl rounded-2xl disabled:cursor-not-allowed"
            >
              Subscribe
            </button>
            {showConfirmation && (
                <div className="mt-2 text-2xl p-3 bg-[#1E3A2B] text-white text-center rounded">
                  Subscribed Successfully!!
                </div>
              )}
          </form>
        </div>
        <p className="font-jostRegular text-sm text-center mb-[16px] mt-[0px]">
          Copyright 2024 Vriksh
        </p>
      </footer>
    </>
  );
};


