import React from "react";
import { Link } from "react-router-dom";
import GreenBitLogo from "../Images/black white leaf logo.png";

const url =
  "https://www.google.com/url?q=https%3A%2F%2Fchat.whatsapp.com%2FHToo6tS5XFO0bxbcOQTdy7&sa=D&sntz=1&usg=AOvVaw2jP6yJms6M04eeE0NaLo1P";

function Header() {
  return (
    <header className="px-2  flex sm:gap-0  gap-[45px]  justify-between items-center sticky top-0  z-10 bg-[#f8f4f0]">
      <div className="flex items-center mr-[15px]">
        <img src={GreenBitLogo} alt="" className="w-10 h-10 mr-2 ml-2" />
        <div className="font-sans">
          <p className="text-xs text-gray-600">Maiti Labs</p>
          <p className="text-xl">GreenBit</p>
        </div>
      </div>
      <div className="flex items-center ">
        <Link to="/About" className="sm:mr-4 m-2">
          <button className="group relative font-medium w-24 h-10 text-lg text-black">
            About
          </button>
        </Link>
        <DrawOutlineButton onClick={() => window.open(url, "_blank")}>
          <p className="text-lg">Join Us</p>
        </DrawOutlineButton>
      </div>
    </header>
  );
}

const DrawOutlineButton = ({ children, ...rest }) => {
  return (
    <button
      {...rest}
      className="group relative font-medium sm:w-24 w-20  h-10 text-lg"
    >
      <span>{children}</span>
    </button>
  );
};

export default Header;
