import React, { useRef, useEffect, useState } from 'react';
import Bhairana from "../../assets/images/Bhairana.webp";
import Nanital from "../../assets/images/Nanital.png";
import Nandanvan from "../../assets/images/Nandanvan.png";
import RamBhoomi from "../../assets/images/RamBhoomi.png";
import Tribals from "../../assets/images/Tribals.png";
import Urban from "../../assets/images/Urban.png";
import Devarakeere from "../../assets/images/Devarakee.png";
import Purulia from "../../assets/images/Purulia.png";
import AyodhyaDam from "../../assets/images/AyodhyaDam.png";

const projectData = [
  { img: Bhairana, title: "Bhairana Agroforestry Project - Jaipur Rajasthan" },
  { img: Tribals, title: "Trees for Tribals, Eastern Districts, Tamil Nadu" },
  { img: Nandanvan, title: "Green Nandanvan, Wanowarie Pune " },
  { img: Nanital, title: "Trees for Himalayas, Nanital, UttarKhand" },
  { img: RamBhoomi, title: "Ram Bhoomi Greenery Project" },
  { img: AyodhyaDam, title: "Shree Ram Van at Ayodhya" },
  { img: Devarakeere, title: "Bangalore Urban Forest, Devarakere" },
  { img: Purulia, title: "Forest for Elephant - Purulia, West Bengal" },
  { img: Urban, title: "Delhi Urban Forest, Juanapur, Delhi" }
];

const ActiveProjects = () => {
  const carouselRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const startDragging = (e) => {
    setIsDragging(true);
    setStartX(e.pageX || e.touches[0].pageX);
    setScrollLeft(carouselRef.current.scrollLeft);
  };

  const stopDragging = () => {
    setIsDragging(false);
  };

  const onDrag = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX || e.touches[0].pageX;
    const walk = (x - startX) * 2; // Adjust drag speed
    carouselRef.current.scrollLeft = scrollLeft - walk;
  };

  useEffect(() => {
    const carouselElement = carouselRef.current;

    carouselElement.addEventListener('mousedown', startDragging);
    carouselElement.addEventListener('mouseleave', stopDragging);
    carouselElement.addEventListener('mouseup', stopDragging);
    carouselElement.addEventListener('mousemove', onDrag);

    carouselElement.addEventListener('touchstart', startDragging);
    carouselElement.addEventListener('touchend', stopDragging);
    carouselElement.addEventListener('touchmove', onDrag);

    return () => {
      carouselElement.removeEventListener('mousedown', startDragging);
      carouselElement.removeEventListener('mouseleave', stopDragging);
      carouselElement.removeEventListener('mouseup', stopDragging);
      carouselElement.removeEventListener('mousemove', onDrag);

      carouselElement.removeEventListener('touchstart', startDragging);
      carouselElement.removeEventListener('touchend', stopDragging);
      carouselElement.removeEventListener('touchmove', onDrag);
    };
  }, [isDragging, startX, scrollLeft]);

  return (
    <div className="text-center p-4 rounded-lg overflow-hidden">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-green-900 mb-6">Active Projects</h2>
      <div className="overflow-hidden relative w-full h-auto rounded-lg" ref={carouselRef} style={{ cursor: isDragging ? 'grabbing' : 'grab' }}>
        <div className="flex space-x-6 sm:space-x-6 md:space-x-8 lg:space-x-10 whitespace-nowrap">
          {projectData.map((project, index) => (
            <div key={index} className="bg-[#1E3A2B] text-white rounded-lg shadow-lg p-6 w-full sm:w-[340px] md:w-[400px] lg:w-[450px] flex-shrink-0 flex flex-col justify-between">
              {/* Image */}
              <img
                src={project.img}
                alt={`Image of ${project.title}`}
                className="rounded-lg object-cover w-full h-48 sm:h-56 md:h-64 lg:h-72 mb-6"
              />

              {/* Title */}
              <div className="min-h-[60px] max-h-[100px] mb-6">
                <h3 className="text-sm sm:text-lg md:text-xl lg:text-2xl font-bold text-center break-words whitespace-normal leading-tight">
                  {project.title}
                </h3>
              </div>

              {/* Buttons */}
              <div className="flex justify-between space-x-4 sm:space-x-6">
                <button className="bg-white text-[#1E3A2B] font-bold py-1 px-4 sm:py-2 sm:px-6 lg:py-3 lg:px-8 rounded-full text-sm sm:text-base lg:text-lg">
                  Invest
                </button>
                <button className="bg-white text-[#1E3A2B] font-bold py-1 px-4 sm:py-2 sm:px-6 lg:py-3 lg:px-8 rounded-full text-sm sm:text-base lg:text-lg">
                  Know More
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ActiveProjects;
