import "./App.css";
import Header from "./myComponents/Header";
import Footer from "./myComponents/footer";
import Signup from "./myComponents/Signup";
import Login from "./myComponents/login";
import Homepage from "./myComponents/Homepage";
import AddBlog from "./myComponents/AddBlog.js";
import OTP from "./myComponents/OTP";
import Awareness from "./myComponents/AwarenessPortal";
import "./css/awareness.css";
import "./css/career.css";
import "./css/contact.css";
import About from "./myComponents/About";
import Team from "./myComponents/Team";
import Career from "./myComponents/Career.js";
import Contact from "./myComponents/Contact";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import BlogDetail from "./myComponents/BlogDetail.js";
import { useAppContext } from "./appContext.js";
import GreenBit from "../src/myComponents/GreenBit.js";
import Decarbonn from "./myComponents/Decarbonn";
import Vriksh from "./myComponents/Vriksh";
import QuestionDetailPage from "./myComponents/QuestionDetail.js";
import BackToTop from "./myComponents/BackToTop.js";
import Dashboard from "./myComponents/Dashboard";
import UnsubscribePage from "./myComponents/Unsubscribe.js";
import ResetPassword from "./myComponents/ResetPassword.js";
import ActivitiesCarousel from "./myComponents/Activities/ActivitesCarousel.js";

function App() {
  const { setAuthUser, isUserLoggedIn, setIsUserLoggedIn } = useAppContext();
  // Check if the user is logged in using useEffect
  useEffect(() => {
    // Check for the presence of a token in localStorage or any other storage mechanism
    const token = localStorage.getItem("authToken"); // Change 'authToken' to your token key
    if (!token) {
      setIsUserLoggedIn(false);
      setAuthUser(null);
    }
    // Update the user authentication state based on the presence of the token
    if (token) {
      setIsUserLoggedIn(true);
      const user = JSON.parse(localStorage.getItem("authUser"));
      setAuthUser(user);
    } else {
      setIsUserLoggedIn(false);
    } // eslint-disable-next-line
  }, [isUserLoggedIn]);

  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/awareness" element={<Awareness />} />
          <Route path="/addblog" element={<AddBlog />} />
          <Route path="/career" element={<Career />} />
          <Route path="/about" element={<About />} />
          <Route path="/team" element={<Team />} />
          <Route path="/GreenBit" element={<GreenBit />} />
          <Route path="/DeCarbonn" element={<Decarbonn />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/otp" element={<OTP />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/blog/:id" element={<BlogDetail />} />
          <Route path="/Vriksh" element={<Vriksh />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/question/:question_id" element={<QuestionDetailPage />} />
          <Route path="/unsubscribe" element={<UnsubscribePage />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/activities" element={<ActivitiesCarousel/>} />
        
        </Routes>
        <BackToTop/>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
