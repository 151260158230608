import React, { useState } from "react";
import Img from "../img/SignUpImage.jpg";

import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Row,
  Col,
  Container,
  Input,
  Button,
} from "reactstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SignInGoogle from "./SignInGoogle";

function Signup() {
  const navigate = useNavigate();
  const [matchPassword, setMatchPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(e.target.confirmPassword.value !== e.target.password.value){
      setMatchPassword(true);
      return;
    }
    setMatchPassword(false);

    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      password: e.target.password.value,
      phone: e.target.phone.value,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/signup",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        alert("SUCCESS");
      }

      if (response.status === 400) {
        alert("already registered");
      }

      setTimeout(() => {
        navigate("/OTP", {
          state: {
            name: formData.name,
            email: formData.email,
            password: formData.password,
            phone: formData.phone,
          },
        });
      }, 1);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div>
        <CardBody className="overflow-hidden">
          <Row>
            {/* left part */}
            <Col
              md={12}
              lg={6}
              className="mt-[-15px] md:bg-white lg:bg-[#f8f4f0]   sm:bg-[#f8f4f0]   bg-[#f8f4f0] pr-[0] flex items-center justify-center h-screen lg:h-auto md:h-auto sm:h-auto xl:h-auto"
            >
              <div className="p-[10px]  sm:w-[65%]">
                <Form onSubmit={handleSubmit} className="m-10">
                  <h5 className="text-left mb-4 font-bold text-5xl">Signup</h5>
                  <FormGroup>
                    <label htmlFor="name" className="font-semibold ">
                      Name
                    </label>
                    <Input
                      type="text"
                      placeholder="Enter Your Name"
                      id="name"
                      name="name"
                      className="border-gray-300"
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="email" className="font-semibold">
                      Email
                    </label>
                    <Input
                      type="email"
                      placeholder="Enter Your Email"
                      id="email"
                      name="email"
                      className="border-gray-300"
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="password" className="font-semibold">
                      Password
                    </label>
                    <Input
                      type="password"
                      placeholder="Enter Your Password"
                      id="password"
                      name="password"
                      className="border-gray-300"
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="password" className="font-semibold">
                      Confirm Password
                    </label>
                    <Input
                      type="password"
                      placeholder="Confirm Your Password"
                      id="confirmPassword"
                      name="confirmPassword"
                      className="border-gray-300"
                      required
                    />
                    {matchPassword && <p>Password doesn't match, try again</p>}
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="phone" className="font-semibold">
                      Phone
                    </label>
                    <Input
                      type="number"
                      placeholder="Enter Your Phone"
                      id="phone"
                      name="phone"
                      className="border-gray-300"
                      required
                    />
                  </FormGroup>
                  <Container className="text-center">
                    <Button
                      type="submit"
                      className="btn btn-success btn-block color text-white bg-green-900"
                    >
                      SignUp
                    </Button>
                    <Button
                      type="reset"
                      className="btn btn-block m-[10px] text-white bg-green-900"
                    >
                      Clear
                    </Button>
                  </Container>

                </Form>
                <SignInGoogle mode="signUp"/>
              </div>
            </Col>
            {/* right part */}
            <Col
              md={12}
              lg={6}
              className="text-center d-none d-lg-block bg-[#1c4532] overflow-hidden"
            >
              <div>
                <Card className="w-[27vw] h-[50vh] bg-white  relative top-[7vh] left-[12vw] flex rounded  items-center justify-center mt-10 mb-50px">
                  <img alt="Sample" src={Img} className="p-[25px]" />
                  <CardBody>
                    <div className="text-[1.5vw] h-[15vh] text-[#1c4532] font-bold overflow-hidden  ">
                      We make voice of planet Earth in every language
                    </div>
                    {/* <div
                      style={{
                        fontSize: "min(1.4vw,14px)",
                        height: "12vh",
                        color: "#1c4532",
                        textAlign: "justify",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      We at Maiti Labs believe that climate change is a pressing
                      issue and making people aware of it is our core
                      philosophy.
                    </div> */}
                  </CardBody>
                </Card>

                <div className="relative top-[3vh] text-white text-[2vw]   font-bold">
                  Introducing new features
                </div>

                <div className="flex items-center justify-center h-[100%]">
                  {" "}
                  <div className="text-[#7d948f] text-[13px] m-4 w-[21vw] h-[17vh] text-center  overflow-hidden ">
                    Our Team is working on technology tools to address climate
                    change like carbon credit software & data compression models
                    using AI.
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </div>
    </div>
  );
}

export default Signup;
