import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppContext } from '../appContext';
import { FaUser } from 'react-icons/fa';

const QuestionDetailPage = () => {
  const { question_id } = useParams();
  
  const [question, setQuestion] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [answerText, setAnswerText] = useState('');
  const { authUser, setIsUserLoggedIn } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    fetchQuestionDetails();
  }, [question_id]);

  const fetchQuestionDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getQuestion/${question_id}`);
      setQuestion(response.data);
      setAnswers(response.data.answers);
    } catch (error) {
      console.error('Error fetching question details:', error);
    }
  };

  const handlePostAnswer = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setIsUserLoggedIn(false);
      alert('Please log in to post an answer!');
      navigate('/login');
      return;
    }

    try {
      const newAnswer = {
        userId: authUser._id,
        username: authUser.name,
        answerText,
      };

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/addAnswer/${question_id}`, newAnswer, {
        headers: {
          Authorization: token,
        },
      });

      if (response.status === 201) {
        alert('Answer posted successfully');
        fetchQuestionDetails();
        setAnswerText('');
      } else {
        console.error('Failed to post answer:', response.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert('Unauthorized! Please log in again.');
        navigate('/login');
      } else {
        console.error('An error occurred while posting answer:', error);
      }
    }
  };

  return (
    <div className="container mx-auto mt-16 mb-16">
      {question ? (
        <>
          <h1 className="text-3xl font-bold text-center mb-8 text-[#1E3A2B]">{question.questionText}</h1>
          <div className="flex items-center justify-center mb-4">
            <FaUser className="mr-2 text-[#1E3A2B]" />
            <span className="text-gray-600">{question.username}</span>
          </div>

          <div className="mt-8">
            <h2 className="text-2xl font-bold mb-4 text-[#1E3A2B]">Answers</h2>
            {answers.map(answer => (
              <div key={answer._id} className="bg-[#1E3A2B] bg-opacity-10 shadow-md rounded-lg p-4 mb-4 hover:bg-opacity-20 transition duration-200">
                <p className="text-gray-800">{answer.answerText}</p>
                <div className="flex items-center mt-2 text-gray-600">
                  <FaUser className="mr-1 text-[#1E3A2B]" />
                  <span>{answer.username}</span>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-8">
            <h2 className="text-2xl font-bold mb-4 text-[#1E3A2B]">Post a New Answer</h2>
            <textarea
              placeholder="Enter your answer"
              value={answerText}
              onChange={e => setAnswerText(e.target.value)}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#1E3A2B]"
            />
            <button
              onClick={handlePostAnswer}
              className="mt-4 px-6 py-2 bg-[#1E3A2B] text-white rounded-md hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-[#1E3A2B]"
            >
              Post Answer
            </button>
          </div>
        </>
      ) : (
        <p className="text-center text-gray-600">Loading question details...</p>
      )}
    </div>
  );
};

export default QuestionDetailPage;
