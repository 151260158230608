export const navItemsHome = [
  { name: "Home", to: "home" },
  { name: "About", to: "about" },
  { name: "Awareness Portal", to: "awareness" },
  { name: "Vriksh", to: "Vriksh" },
  { name: "Career", to: "career" },
  { name: "Contact", to: "contact" },
  { name: "Team", to: "team" },
];

export const navItems = [
  { name: "Home", to: "/" },
  { name: "About", to: "about" },
  { name: "Awareness Portal", to: "awareness" },
  { name: "Vriksh", to: "Vriksh" },
  { name: "Career", to: "career" },
  { name: "Contact", to: "contact" },
  { name: "Team", to: "team" },
];

export const topics = [
  "Climate",
  "Pollution",
  "Ice Caps",
  "River",
  "Sustainiblity",
  "Green ",
];

export const selectData = [
  {
    id: "Regions",
    label: "Region:",
    options: ["US"],
  },
  {
    id: "Categories",
    label: "Category:",
    options: ["English"],
  },
];

export const teamMembers = [
  {
    name: "Founder's Office Team",
    members: [
      "Anikita -Senior  Operations Manager ",
      "Sagarika - Operations Manager",
    ],
  },
  {
    name: "Product Team",
    members: [
      "Nirbhai -Senior Product Manager",
       "Amul Kumar -Intern",
       "Shirley Asangi -Senior"
      ],
  },
  {
    name: "Software Team",
    members: [
      "Vivek -Senior Software Engineer",
      "Rishi -Engineering Intern",
      "Dhanush -Software Intern ",
      "Joshitha -Software Intern",
      "Arham -Software Intern",
      "Rashi -Software Intern",
      "Mayank -Software Intern",
      "Likhith -Software Intern",
      "Ayush Kumar Rai -Software Intern",
      "Umangh Alok -Software Intern",
      "Krishav Raj Singh-Software Intern",
      "Subham Shreyash -Software Intern"
    ],
  },
  {
    name: "AI & ML Team",
    members: [
      "Kiran -Senior AI Engineer ",
      "Uday -Senior AI Engineer",
      "Annarshya -Ai Ml Intern",
      "Ayush -Ai Ml Intern",
      "Modha -Ai Ml Intern",
    ],
  },
  {
    name: "Marketing Team",
    members: [
      "Shriya -Marketing Intern",
      "Srujan -Senior Video Editor",
      "Rushikesh Pinge -Growth and Operations Intern",
      "Subham Shreyash -Intern",
      "Aryan Mehra -Growth Intern"
    ],
  },
  {
    name: "Design Team",
    members: [
      "Manojith -Senior Designer",
      "Utkrash -Junior Designer",
      "Mohammed Shan -Intern",
      "Ankith K L -Intern"
    ],
  },
  {
    name: "AR & VR Team ",
    members: ["Ritesh -AR VR Intern"],
  },
  {
    name: "Content Team",
    members: ["Ankith K L -Intern"],
  },
];

export const countryLanguageMapping = {
  AF: ["English", "Pashto", "Dari"],
  AL: ["English", "Albanian"],
  DZ: ["English", "Arabic", "Berber"],
  AO: ["English", "Portuguese"],
  AR: ["English", "Spanish"],
  AM: ["English", "Armenian"],
  AU: ["English"],
  AT: ["English", "German"],
  AZ: ["English", "Azerbaijani"],
  BH: ["English", "Arabic"],
  BD: ["English", "Bengali"],
  BY: ["English", "Belarusian", "Russian"],
  BE: ["English", "Dutch", "French", "German"],
  BZ: ["English"],
  BJ: ["English", "French"],
  BT: ["English", "Dzongkha"],
  BO: ["English", "Spanish", "Quechua", "Aymara"],
  BA: ["English", "Bosnian", "Croatian", "Serbian"],
  BW: ["English", "Tswana"],
  BR: ["English", "Portuguese"],
  BN: ["English", "Malay"],
  BG: ["English", "Bulgarian"],
  BF: ["English", "French"],
  BI: ["English", "Kirundi", "French"],
  KH: ["English", "Khmer"],
  CM: ["English", "French"],
  CA: ["English", "French"],
  CV: ["English", "Portuguese"],
  CF: ["English", "French", "Sango"],
  TD: ["English", "French", "Arabic"],
  CL: ["English", "Spanish"],
  CN: ["English", "Chinese"],
  CO: ["English", "Spanish"],
  KM: ["English", "Comorian", "Arabic", "French"],
  CG: ["English", "French"],
  CD: ["English", "French"],
  CR: ["English", "Spanish"],
  CI: ["English", "French"],
  HR: ["English", "Croatian"],
  CU: ["English", "Spanish"],
  CY: ["English", "Greek", "Turkish"],
  CZ: ["English", "Czech"],
  DK: ["English", "Danish"],
  DJ: ["English", "French", "Arabic"],
  DM: ["English"],
  DO: ["English", "Spanish"],
  EC: ["English", "Spanish"],
  EG: ["English", "Arabic"],
  SV: ["English", "Spanish"],
  GQ: ["English", "Spanish", "French", "Portuguese"],
  ER: ["English", "Tigrinya", "Arabic"],
  EE: ["English", "Estonian"],
  ET: ["English", "Amharic"],
  FJ: ["English", "Fijian", "Hindi"],
  FI: ["English", "Finnish", "Swedish"],
  FR: ["English", "French"],
  GA: ["English", "French"],
  GM: ["English"],
  GE: ["English", "Georgian"],
  DE: ["English", "German"],
  GH: ["English"],
  GR: ["English", "Greek"],
  GD: ["English"],
  GT: ["English", "Spanish"],
  GN: ["English", "French"],
  GW: ["English", "Portuguese"],
  GY: ["English"],
  HT: ["English", "French", "Haitian Creole"],
  HN: ["English", "Spanish"],
  HU: ["English", "Hungarian"],
  IS: ["English", "Icelandic"],
  IN: ["English", "Hindi"],
  ID: ["English", "Indonesian"],
  IR: ["English", "Persian"],
  IQ: ["English", "Arabic", "Kurdish"],
  IE: ["English", "Irish"],
  IL: ["English", "Hebrew", "Arabic"],
  IT: ["English", "Italian"],
  JM: ["English"],
  JP: ["English", "Japanese"],
  JO: ["English", "Arabic"],
  KZ: ["English", "Kazakh", "Russian"],
  KE: ["English", "Swahili"],
  KI: ["English"],
  KP: ["English", "Korean"],
  KR: ["English", "Korean"],
  KW: ["English", "Arabic"],
  KG: ["English", "Kyrgyz", "Russian"],
  LA: ["English", "Lao"],
  LV: ["English", "Latvian"],
  LB: ["English", "Arabic", "French"],
  LS: ["English", "Sesotho"],
  LR: ["English"],
  LY: ["English", "Arabic"],
  LI: ["English", "German"],
  LT: ["English", "Lithuanian"],
  LU: ["English", "Luxembourgish", "French", "German"],
  MG: ["English", "Malagasy", "French"],
  MW: ["English", "Chichewa"],
  MY: ["English", "Malay"],
  MV: ["English", "Dhivehi"],
  ML: ["English", "French"],
  MT: ["English", "Maltese"],
  MH: ["English", "Marshallese"],
  MR: ["English", "Arabic"],
  MU: ["English", "French"],
  MX: ["English", "Spanish"],
  FM: ["English"],
  MD: ["English", "Romanian"],
  MC: ["English", "French"],
  MN: ["English", "Mongolian"],
  ME: ["English", "Montenegrin"],
  MA: ["English", "Arabic", "Berber"],
  MZ: ["English", "Portuguese"],
  MM: ["English", "Burmese"],
  NA: ["English"],
  NR: ["English", "Nauruan"],
  NP: ["English", "Nepali"],
  NL: ["English", "Dutch"],
  NZ: ["English", "Māori"],
  NI: ["English", "Spanish"],
  NE: ["English", "French"],
  NG: ["English"],
  NO: ["English", "Norwegian"],
  OM: ["English", "Arabic"],
  PK: ["English", "Urdu"],
  PW: ["English", "Palauan"],
  PA: ["English", "Spanish"],
  PG: ["English", "Tok Pisin", "Hiri Motu"],
  PY: ["English", "Spanish", "Guarani"],
  PE: ["English", "Spanish", "Quechua", "Aymara"],
  PH: ["English", "Filipino"],
  PL: ["English", "Polish"],
  PT: ["English", "Portuguese"],
  QA: ["English", "Arabic"],
  RO: ["English", "Romanian"],
  RU: ["English", "Russian"],
  RW: ["English", "Kinyarwanda", "French"],
  KN: ["English"],
  LC: ["English"],
  VC: ["English"],
  WS: ["English", "Samoan", "English"],
  SM: ["English", "Italian"],
  ST: ["English", "Portuguese"],
  SA: ["English", "Arabic"],
  SN: ["English", "French"],
  RS: ["English", "Serbian"],
  SC: ["English", "French", "Seychellois Creole"],
  SL: ["English"],
  SG: ["English", "Malay", "Mandarin", "Tamil"],
  SK: ["English", "Slovak"],
  SI: ["English", "Slovene"],
  SB: ["English"],
  SO: ["English", "Somali", "Arabic"],
  ZA: ["English", "Zulu", "Xhosa", "Afrikaans", "Southern Sotho", "Tswana"],
  ES: ["English", "Spanish"],
  LK: ["English", "Sinhala", "Tamil"],
  SD: ["English", "Arabic"],
  SR: ["English", "Dutch"],
  SE: ["English", "Swedish"],
  CH: ["English", "German", "French", "Italian", "Romansh"],
  SY: ["English", "Arabic"],
  TW: ["English", "Mandarin"],
  TJ: ["English", "Tajik"],
  TZ: ["English", "Swahili"],
  TH: ["English", "Thai"],
  TL: ["English", "Tetum", "Portuguese"],
  TG: ["English", "French"],
  TO: ["English", "Tongan"],
  TT: ["English"],
  TN: ["English", "Arabic"],
  TR: ["English", "Turkish"],
  TM: ["English", "Turkmen"],
  UG: ["English", "Swahili"],
  UA: ["English", "Ukrainian"],
  AE: ["English", "Arabic"],
  GB: ["English"],
  US: ["English"],
  UY: ["English", "Spanish"],
  UZ: ["English", "Uzbek"],
  VU: ["English", "Bislama", "French"],
  VE: ["English", "Spanish"],
  VN: ["English", "Vietnamese"],
  YE: ["English", "Arabic"],
  ZM: ["English"],
  ZW: ["English", "Shona", "Ndebele"],
};
