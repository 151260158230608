import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";

export const ContactForm = ({partner}) => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
          const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/sendMail`,
            {...formData, partner},
            {
              headers: {
                "Content-Type": 'application/json'
              }
            }
          );
          console.log(res.data);
          Swal.fire({
            position: "center",
            icon:"success",
            title: "Email sent successfully",
            showConfirmButton: false,
            timer: 1500,
          })
          setFormData({
            name: "",
            email: "",
            message: "",
          });
        } catch (err) {
          console.error("Error sending email: ", err);
          Swal.fire({
            position: "center",
            icon:"error",
            title: "Something went wrong!!",
            showConfirmButton: false,
            timer: 1500,
          })
        }
      };

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

    return (
        <div
            style={{height: window.innerWidth>768?'340px':'290px'}}
        >
            <form 
                onSubmit={handleSubmit} 
            >
                <div className="md:flex block mb-4">
                <input
                    type="text"
                    name="name"
                    className="p-2 border md:w-[50%] w-[100%] border-gray-300 rounded mr-2  md:mt-0 mt-2"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    className="p-2 border md:w-[50%] w-[100%] border-gray-300 rounded  md:mt-0 mt-2"
                    placeholder="Email"
                    onChange={handleChange}
                    required
                />
                </div>
                <textarea
                className="p-2 border w-[100%] h-[180px] border-gray-300 rounded resize-none"
                placeholder="Message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                ></textarea>
                <button
                type="submit"
                className="h-[15%] w-[100%] text-white bg-black rounded-md"
                >
                Send
                </button>
            </form>
        </div>
    )
}