import { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom"

export const VrikshNavBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
      const handleScroll = (event) => {
        event.preventDefault();
        const targetId = event.target.getAttribute('href').slice(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          const navbarHeight = document.querySelector('.navbar')?.offsetHeight || 0;
          const targetPosition = targetElement.offsetTop - navbarHeight;
          window.scrollTo({
            top: targetPosition,
            behavior: 'smooth'
          });
        }
      };
  
      const links = document.querySelectorAll('a[href^="#"]');
      links.forEach(link => {
        link.addEventListener('click', handleScroll);
      });
  
      return () => {
        links.forEach(link => {
          link.removeEventListener('click', handleScroll);
        });
      };
    }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
      };

    return (
      <header className="navbar z-[1001] fixed w-full py-[8px] xl:py-6 flex z-100 items-center justify-between bg-green-bg lg:grid lg:grid-cols-6">
        <h1 className="pl-[2rem] text-white lg:col-span-2 cs:pl-[5rem] xl:pl-[6.81rem] py-[1rem] pr-[4rem] text-[3rem] xl:text-[3rem] font-jostSemiBold font-medium">
          Vriksh
        </h1>
        <div className="relative lg:col-span-4 flex items-center">
          <button
            onClick={toggleMenu}
            className="text-2xl md:text-3xl text-white mr-10 focus:outline-none lg:hidden"
          >
            <FaBars />
          </button>
          <div
            className={`${
              isOpen ? "block" : "hidden"
            } xl:flex lg:flex  flex-col lg:flex-row items-center lg:bg-transparent bg-white xl:bg-white lg:bg-white lg:space-x-8 w-[10rem] lg:w-full xl:h-[3.5rem]  lg:h-[3.2rem] mt-1 md:mt-0 lg:w-full absolute lg:relative top-full right-[1rem] lg:top-0 lg:left-0 xl:rounded-l-[2rem] lg:rounded-l-[2rem] lg:rounded-none rounded-lg lg:shadow-none shadow-lg z-10 lg:py-0 py-3`}
          >
            <nav className="flex flex-col text-center lg:flex-row lg:justify-evenly items-center  lg:mr-[4rem] md:mr-[3rem] lg:space-x-8 w-full lg:w-full text-green-bg2 rounded-lg lg:rounded-none">
              <a
                href="#home"
                className="transition ease-in-out hover:-translate-y-1 hover:scale-105 mx-3 my-2 cursor-pointer hover:text-[#496c62]"
              >
                Home
              </a>
              <a
                href="#about"
                className="transition ease-in-out hover:-translate-y-1 hover:scale-105 mx-3 my-2 cursor-pointer hover:text-[#496c62]"
              >
                About
              </a>
              <a
                href="#contact"
                className="transition ease-in-out hover:-translate-y-1 hover:scale-105 mx-3 my-2 cursor-pointer hover:text-[#496c62]"
              >
                Plant Now
              </a>
              <a
              className=" transition ease-in-out hover:-translate-y-1 hover:scale-105 mx-3 my-2 cursor-pointer hover:text-[#496c62]"
              href="#partners"
              >
                Our Partners
              </a>
              <div
              className=" transition ease-in-out hover:-translate-y-1 hover:scale-105 mx-3 my-2 cursor-pointer hover:text-[#496c62]"
              href="#partners"
              onClick={() => navigate('/')}
              >
                Maiti Labs
              </div>
            </nav>
          </div>
        </div>
      </header>
    )
}