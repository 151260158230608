import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useAppContext } from '../appContext';
import { FaComments, FaUser } from 'react-icons/fa';

const Discussion = () => {
  const [questions, setQuestions] = useState([]);
  const [questionText, setQuestionText] = useState('');
  const { authUser, setIsUserLoggedIn } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getQuestions`);
      setQuestions(response.data);
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };

  const handlePostQuestion = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setIsUserLoggedIn(false);
      alert('Please log in to post a question!');
      navigate('/login');
      return;
    }

    try {
      const newQuestion = {
        userId: authUser._id,
        username: authUser.name,
        questionText, // Ensure this matches the schema field name
      };

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/addQuestion`, newQuestion, {
        headers: {
          Authorization: token, // Ensure token is sent in Authorization header
        },
      });

      if (response.status === 201) {
        alert('Question posted successfully');
        fetchQuestions();
        setQuestionText('');
      } else {
        console.error('Failed to post question:', response.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert('Unauthorized! Please log in again.');
        navigate('/login');
      } else {
        console.error('An error occurred while posting question:', error);
      }
    }
  };

  return (
    <div className="container mx-auto mt-8 mb-8 p-4 bg-light-green-50 rounded-lg shadow-md">
      <h3 className="text-3xl font-bold text-center mb-8 text-[#1E3A2B]">Discussion</h3>

      <div className="grid grid-cols-1 gap-4">
        {questions.map(question => (
          <div key={question._id} className="bg-white shadow-md rounded-lg p-4">
            <Link to={`/question/${question._id}`}>
              <h3 className="text-xl font-semibold text-[#1E3A2B] hover:text-[#1E3A2B]">{question.questionText}</h3>
            </Link>
            <p className="text-gray-600 flex items-center">
              <FaUser className="mr-1 text-[#1E3A2B]" />
              <span className="text-sm">{question.username}</span>
            </p>
            <div className="mt-2">
              {question.answers.slice(0, 2).map((answer, index) => (
                <div key={index} className="border-t mt-2 pt-2 text-sm text-gray-800">
                  {answer.answerText}
                </div>
              ))}
              <div className="flex items-center mt-2">
                <Link to={`/question/${question._id}`} className="text-[#1E3A2B] hover:text-[#1E3A2B] flex items-center">
                  <FaComments className="mr-1" /> View all comments
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-8">
        <h3 className="text-2xl font-bold mb-4 text-[#1E3A2B]">Post a New Question</h3>
        <input
          type="text"
          placeholder="Enter your question"
          value={questionText}
          onChange={e => setQuestionText(e.target.value)}
          className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#1E3A2B]"
        />
        <button
          onClick={handlePostQuestion}
          className="mx-auto mt-4 px-6 py-2 bg-[#1E3A2B] text-white rounded-[25px] shadow-xl transition-transform transform scale-105 focus:outline-none focus:ring-2 focus:ring-[#1E3A2B] hover:bg-white hover:text-[#1E3A2B]"
        >
          <p className="transition-transform transform scale-105 group-hover:scale-100 font-medium hover:text-[#1E3A2B]">
            Post Question
          </p>
        </button>
      </div>
    </div>
  );
};

export default Discussion;
