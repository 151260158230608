import React from "react";
import Img from "../img/logInImage.jpg";
import { Form, FormGroup, Button, Input, Card, CardBody } from "reactstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAppContext } from "../appContext";
import { useState, useEffect } from "react";
import SignInGoogle from "./SignInGoogle";

function Login() {
  const [token, setToken] = useState(null);
  const { setAuthUser, setIsUserLoggedIn } = useAppContext();

  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    if (storedToken) {
      setToken(storedToken);
      setIsUserLoggedIn(true);
    } else {
      setIsUserLoggedIn(false);
    }
  }, [setIsUserLoggedIn]);

  const login = (newToken) => {
    setToken(newToken);
    localStorage.setItem("authToken", newToken);
    setIsUserLoggedIn(true);
  };

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const queryParams = {
      email: e.target.email.value,
      password: e.target.password.value,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/login",
        queryParams
      );
      console.log(response.data);
      if (response.data.msg === "login success") {
        login(`Bearer ${response.data.token}`);
        localStorage.setItem("authUser", JSON.stringify(response.data.user));
        setAuthUser(response.data.user);

        Swal.fire({
          position: "center",
          icon: "success",
          title: "Logged in Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        window.history.back();
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Something Went Wrong!!! Try Again.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Something Went Wrong!!! Try Again.",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <div className="flex" style={{ height: "100vh" }}>
      <div className="flex-1 bg-[#1c4532] xl:block hidden">
        <div>
          <Card className="w-[27vw] h-[50vh] bg-white relative top-[7vh] left-[12vw] flex rounded items-center justify-center mt-10 mb-50px">
            <img alt="Sample" src={Img} className="p-[2px]" />
            <CardBody>
              <div className="text-[1vw] h-[15vh] text-[#1c4532] font-bold overflow-hidden">
                We at Maiti Labs believe that climate change is a pressing issue and making people aware of it is our core philosophy.
              </div>
            </CardBody>
          </Card>

          <div className="relative top-[3vh] left-[25vh] text-white text-[2vw] font-bold">
            Introducing new features
          </div>

          <div className="flex items-center justify-center h-[100%]">
            <div className="text-[#7d948f] text-[13px] m-4 w-[21vw] h-[17vh] text-center overflow-hidden">
              Our Team is working on technology tools to address climate change like carbon credit software & data compression models using AI.
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1 md:bg-white lg:bg-[#f8f4f0] sm:bg-[#f8f4f0] bg-[#f8f4f0]">
        <div className="text-black-700 font-bold text-5xl mt-24 ml-14">
          Welcome Back!
        </div>
        <div className="m-6 sm:m-24">
          <Form onSubmit={handleLogin}>
            <FormGroup>
              <label className="text-sm">Username</label>
              <Input
                type="text"
                placeholder="Micheal Scott"
                id="username"
                className="border-gray-300 mt-1 bg-gray-100"
                required
              />
            </FormGroup>
            <FormGroup>
              <label className="text-sm">Email</label>
              <Input
                type="email"
                placeholder="Michealscott@dundermifflin.co.in"
                id="email"
                className="border-gray-300 mt-1 bg-gray-100"
                required
              />
            </FormGroup>
            <FormGroup>
              <label className="text-sm">Password</label>
              <Input
                type="password"
                placeholder="SavePaper&Trees"
                id="password"
                className="border-gray-300 mt-1 bg-gray-100"
                required
              />
            </FormGroup>
            <Button
              type="submit"
              className="btn btn-success btn-block color rounded-xl text-white bg-[#1c4532]"
              block
            >
              Login
            </Button>
          </Form>
          <div className="mt-1">
            <SignInGoogle mode="signIn"/>
          </div>
          <div className="text-base text-center mt-4">
            <a 
              href="/reset-password" 
              className="text-[#1c4532] hover:text-[#145a32] font-semibold"
              onClick={(e) => {
                e.preventDefault();
                navigate("/reset-password");
              }}
            >
              Forgot Password?
            </a>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Login;
