import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const UnsubscribePage = () => {
  const location = useLocation();
  const [message, setMessage] = useState('');

  // Extract query parameters
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');

  useEffect(() => {
    const handleUnsubscribe = async () => {
      if (email) {
        try {
          const response = await axios.delete(`${process.env.REACT_APP_API_URL}/unsubscribe`, {
            data: {email},
            headers: {
                'Content-Type': 'application/json',
            }
          });
          console.log(response);
          if (response.status===200) {
            setMessage('You have been unsubscribed successfully.');
          } else {
            const error = await response.text();
            setMessage(`Unsubscribe failed: ${error}`);
          }
        } catch (error) {
          console.error('Unsubscribe error:', error);
          setMessage('There was an issue with your unsubscription. Make sure you were subscribed.');
        }
      }
    };

    handleUnsubscribe();
  }, [email]);

  return (
    <div className="flex flex-col items-center justify-center h-full text-center m-32">
      <h1 className="text-3xl mb-4">Unsubscribe</h1>
      <p className="text-xl">{message}</p>
    </div>
  );
};

export default UnsubscribePage;
