import axios from "axios"; // Import Axios
import React, { useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useAppContext } from "../appContext";
import { topics } from "../data";
import useBlogQueries from '../hooks/useBlogQueries';

export default function AddBlog() {
  const { setIsUserLoggedIn } = useAppContext();
  const [onFocus, setOnFocus] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    image: "",
    content: "",
    author: "",
    twitter: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlefileupload = async (e) => {
    const image = e.target.files[0];
    if (image) {
      const data = new FormData();
      data.append("file", image);

      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/file/upload",
          data
        );
        setFormData({ ...formData, image: response.data });
      } catch (error) {
        console.error("Error occurred while uploading the image", error);
      }
    }
  };


  const { mutation } = useBlogQueries();

  const editorRef = useRef();

  return (
    <>
      <div className="container">
        <form id="myform" 
          onSubmit={(e) => {
            e.preventDefault();
            mutation.mutate(formData, setIsUserLoggedIn);
            setFormData({
              title: "",
              image: "",
              content: "",
              author: "",
              twitter: "",
              email: "",
            });
          }}>
          <div className="row">
            <div className="col-md-2">
              <label htmlFor="title" className="form-label">
                Title
              </label>
            </div>
            <div className="col-md-10">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  name="title"
                  value={formData.title}
                  onClick={() => setOnFocus(true)}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div
              className={onFocus ? "pl-[220px] pb-3 items-center" : "hidden"}
            >
              <div className="relative flex flex-row justify-around w-full md:w-auto ">
                <h1 className="items-center p-2">Trending Topics: </h1>
                {topics.map((topic, index) => (
                  <div
                    key={index}
                    className="text-sm text-center border  rounded-[35px] border-gray-400 p-2"
                    outline
                  >
                    {topic}
                  </div>
                ))}
              </div>
            </div>

            <div className="col-md-2">
              <label htmlFor="image" className="form-label">
                photo
              </label>
            </div>
            <div className="col-md-10">
              <div className="mb-3">
                <input
                  type="file"
                  className="form-control"
                  id="image"
                  name="image"
                  accept=".jpeg,.png,.jpg"
                  onClick={() => {
                    setOnFocus(false);
                  }}
                  onChange={handlefileupload}
                  required
                />
              </div>
            </div>
            <div className="col-md-2">
              <label htmlFor="author" className="form-label">
                Author
              </label>
            </div>
            <div className="col-md-10">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="author"
                  name="author"
                  value={formData.author}
                  onClick={() => {
                    setOnFocus(false);
                  }}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-2">
              <label htmlFor="author" className="form-label">
                Email
              </label>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onClick={() => {
                    setOnFocus(false);
                  }}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-2">
              <label htmlFor="author" className="form-label">
                Twitter{" "}
                <span className="text-sm text-gray-500">(optional)</span>
              </label>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="twitter"
                  name="twitter"
                  value={formData.twitter}
                  onClick={() => {
                    setOnFocus(false);
                  }}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <label htmlFor="author" className="form-label">
            Content :
          </label>
          <Editor
            apiKey="6f4fynkjawza87ot8g2f7gw09j33s9bu1dn7a6qfuxk2l0jz"
            onClick={() => {
              setOnFocus(false);
            }}
            onEditorChange={() => {
              setFormData({
                ...formData,
                content: editorRef.current.getContent(),
              });
            }}
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue=""
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Gilroy-Light,Arial,sans-serif; font-size:14px }",
            }}
          />

          <div className="flex mx-auto w-[200px] mt-[30px]">
            <button
              type="reset"
              className="h-[15%] w-[100%] text-white bg-black rounded-md"
              style={{ marginRight: 10 }}
            >
              Clear
            </button>
            <button
              type="submit"
              className="h-[15%] w-[100%] text-white bg-black rounded-md"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
