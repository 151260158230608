import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { topics, selectData, countryLanguageMapping } from "../data";
import FAQ from "./FAQ";
import Discussion from "./Discussion";
import GetLocation from "../utils/getLocation";
import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Helmet } from "react-helmet";
import useBlogQueries from '../hooks/useBlogQueries';
const getLanguageByCountry = (countryCode) => {
  return countryLanguageMapping[countryCode] || ["English"];
};

export default function Awareness() {
  const [currentPage, setCurrentPage] = useState(1);
  const [blogs, setBlogs] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState(selectData);
  const [searchText, setSearchText] = useState("");
  const [disabled, setDisabled] = useState(false);
  const {query} = useBlogQueries();

  useEffect(() => {
    GetLocation()
      .then((countryCode) => {
        const languages = getLanguageByCountry(countryCode[1]);
        setData((prevData) => {
          const updatedData = [...prevData];
          updatedData[1].options = languages;
          updatedData[0].options = countryCode;
          return updatedData;
        });
      })
      .catch((error) => {
        console.error("Failed to get location or languages:", error);
      });
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const trimContent = (content, maxChars = 50) => {
    return content.length > maxChars
      ? content.substring(0, maxChars) + "..."
      : content;
  };

  useEffect(() => {
    if(query?.data)
      setBlogs(query?.data);
  }, [query?.data]);

  const filterBlogsByTopic = (topic) => {
    console.log("Filtering blogs by topic:", topic);
    const filteredBlogs = query?.data.filter(
      (blog) =>
        blog.title.toLowerCase().includes(topic.toLowerCase()) ||
        blog.content.toLowerCase().includes(topic.toLowerCase())
    );
    console.log("Filtered blogs:", filteredBlogs);
    setBlogs(filteredBlogs);
  };

  const filterBlogsByKeyword = (keyword) => {
    const filteredBlogsWithTitle = query?.data.filter((blog) =>
      blog.title.toLowerCase().includes(keyword.toLowerCase())
    );
    const filteredBlogsWithContent = query?.data.filter(
      (blog) =>
        blog.content.toLowerCase().includes(keyword.toLowerCase()) &&
        !filteredBlogsWithTitle.includes(blog)
    );

    setBlogs([...filteredBlogsWithTitle, ...filteredBlogsWithContent]);
  };

  const MyImage = ({ image }) => (
    <LazyLoadImage
      alt={image.alt}
      effect="blur"
      wrapperProps={{
        style: { transitionDelay: "0.1s" },
      }}
      src={image.src}
    />
  );

  const BlogList = useMemo(()=> {
    const currentBlogs = blogs.slice((currentPage-1)*5, currentPage*5);
    setDisabled(currentBlogs.length < 5);

    return (
      <>
        {currentBlogs.map((blog, index) => (
          <Link
            className="hover:text-inherit"
            to={`/blog/${blog._id}`}
            key={index}
            state={blog}
          >
            <div className="mx-[20px] my-[25px] flex">
              <div className="w-[50%] sm:w-[30%] bg-cover mr-[20px] overflow-hidden">
                <MyImage
                  image={{ src: blog.image, alt: "blogImage" }}
                />
              </div>
              <div className="w-full sm:w-[70%]">
                <div className="text-[28px] leading-[1.4em] font-sans font-bold text-[#333333]">
                  {blog.title}
                </div>
                <div
                  className="my-[10px]"
                  dangerouslySetInnerHTML={{
                    __html: trimContent(blog.content),
                  }}
                ></div>
              </div>
            </div>
          </Link>
        ))}
      </>
    )
  }, [blogs, currentPage]);

  const searchBlog = async () => {
    const storedToken = localStorage.getItem("authToken");
    if(searchText){
      const res = await axios.get(process.env.REACT_APP_API_URL + "/searchBlog", {
        headers: {
          Authorization: storedToken,
        },params: {
          keyword: searchText
        },
      });

      setBlogs(res.data.blogs);
    } else {
      setBlogs(query?.data);
    }
  }

  return (
    <>
      <Helmet>
        <title>Maiti Labs - Awareness Portal</title>
        <meta name="description" content="The Awareness Portal provides climate information in various languages to help organizations and people take action." />
        <link rel="canonical" href="https://www.maitilabs.org/awareness" />
        <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Awareness Portal",
            "url": "https://www.maitilabs.org/awareness",
            "description": "The Awareness Portal provides climate information in various languages to help organizations and people take action.",
            "mainEntity": {
              "@type": "Organization",
              "name": "Maiti Labs",
              "logo": "https://www.maitilabs.org/static/media/Logo.00129f1c05848c338b15.png"
              }
          }
        `}
      </script>
      </Helmet>
      <div className="container mx-auto mt-16 mb-15">
        <div className="w-[80%] mx-auto mt-[57px]">
          <h1 className="font-[Gilroy-Bold] text-[60px] text-[#333333] leading-[1.2em] tracking-[-0.04em] text-center ">
            {" "}
            Awareness Portal
          </h1>
          <div className="w-[100%] mt-[34px] font-[Belleza] font-light text-[#1E3A2B] text-[27px] leading-[1.2em] text-center ">
            The articles can be read in any language in the world. The goal is
            to make Climate Information accessible for organizations and people
            to take action.
          </div>
        </div>
        <div className="flex flex-col sm:flex-row items-center justify-center mb-8">
          <input
            className="w-full mt-[15px] sm:w-2/3 md:w-1/2 lg:w-1/3 px-4 py-2 mb-2 sm:mb-0 mr-0 sm:mr-2 rounded-lg placeholder-gray-600 placeholder-opacity-75 bg-gray-200 focus:outline-none"
            placeholder="Search"
            onChange={(e)=>setSearchText(e.target.value)}
          />

          <button
            className=" w-full group  relative sm:w-auto px-4  font-mediumcursor-pointer  h-[40px] delay-75 rounded-lg bg-[#1e3a2b] hover:bg-slate-100/50 hover:shadow-md hover:shadow-emerald-800/50 text-slate-100 transition-colors duration-[300ms] hover:text-[#1e3a2b]"
            onClick={searchBlog}
          >
            <span>
              <div className="transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-105 mx-3 my-2 cursor-pointer hover:text-[#496c62]">
                Search
              </div>
            </span>
          </button>
        </div>
        <div className="relative">
          <div className="grid grid-cols-1 overflow-y-auto sm:grid-cols-[80%,20%] md:grid-cols-[60%,40%] lg:grid-cols-[70%,30%] xl:grid-cols-[70%,30%] h-screen mb-[20px]">
            {/* Blog section */}

            <div className="row-span-2  sm:h-[70vh] h-full ">
              <div className="flex  justify-between">
                <h2 className="font-[Gilroy-Light] text-[35px] leading-[1.2em] mb-4">
                  Blogs
                </h2>
                <button className=" w-[40%] sm:w-auto group  relative  sm:px-4 px-0  font-mediumcursor-pointer  h-[40px] delay-75 rounded-lg bg-[#1e3a2b] hover:bg-slate-100/50 hover:shadow-md hover:shadow-emerald-800/50 text-slate-100 transition-colors duration-[300ms] hover:text-[#1e3a2b]">
                  <span>
                    <a
                      className="transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-105 mx-3 my-2 cursor-pointer hover:text-[#496c62]"
                      href="/AddBlog"
                    >
                      Add Blog
                    </a>
                  </span>
                </button>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4">
                {blogs.length > 0 ? (
                  BlogList
                ) : (
                  <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4">
                    {[1, 2, 3, 4, 5].map((_, index) => (
                      <div key={index} className="mx-[20px] my-[25px] flex">
                        <div className="w-[50%] sm:w-[30%] bg-cover mr-[20px] overflow-hidden">
                          <div className="rounded-full bg-gray-300 h-12 w-12 animate-pulse"></div>
                        </div>
                        <div className="w-full sm:w-[70%]">
                          <div className="h-4 bg-gray-300 mb-2 rounded animate-pulse w-3/4"></div>
                          <div className="space-y-2">
                            <div className="h-4 bg-gray-300 rounded animate-pulse"></div>
                            <div className="h-4 bg-gray-300 rounded w-5/6 animate-pulse"></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* Filter and Trending topics section */}
            <div className="mb-0 flex flex-col sm:flex-row justify-center md:justify-center">
              {/* Filter section */}
              <div className="w-full sm:w-1/2">
                <h2 className="font-[Gilroy-Light] text-[35px] leading-[1.2em]">
                  Filter
                </h2>
                <div className="flex flex-col">
                  <div className="w-fit mx-auto my-[30px]">
                    <input onChange={(e) => setKeyword(e.target.value)}
                      className="mr-2 w-full sm:w-[100%] h-auto rounded-lg placeholder-gray-600 placeholder-opacity-75 bg-gray-200 focus:outline-none p-[10px] text-sm mb-3 sm:block hidden"
                      placeholder="Search by keyword"
                    />
                    {data.map((item) => (
                      <React.Fragment key={item.id}>
                        <label
                          htmlFor={item.id}
                          className="w-[100%] sm:w-[100%] h-auto text-xs sm:text-[15px] mb-2"
                        >
                          {item.label}
                        </label>
                        <select
                          name={item.id}
                          id={item.id}
                          className="mr-2 w-[100%] sm:w-[100%] h-auto rounded-lg placeholder-gray-600 placeholder-opacity-75 bg-gray-200 focus:outline-none p-[8px] text-base mb-3"
                        >
                          {item.options.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </React.Fragment>
                    ))}

                    <button 
                      onClick={() => filterBlogsByKeyword(keyword)}
                      className="w-full group relative font-medium cursor-pointer h-[40px] delay-75 rounded-lg bg-[#1e3a2b] hover:bg-slate-100/50 hover:shadow-md hover:shadow-emerald-800/50 text-slate-100 transition-colors duration-[300ms] hover:text-[#1e3a2b]">
                      <span>
                        <div className="transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-105 mx-3 my-2 cursor-pointer hover:text-[#496c62]">
                          Filter
                        </div>
                      </span>
                    </button>
                  </div>
                </div>

                {/* Trending topics section */}
                <div>
                  <div className="flex flex-col md:flex-row md:flex-wrap items-center">
                    <div className="font-[Gilroy-Light] text-[35px] leading-[1.2em] md:ml-[31px] mb-4 md:mb-0">
                      Trending Topics
                    </div>
                    <div className="justify-around w-full md:w-auto ">
                      {topics.map((topic, index) => (
                        <Button
                          key={index}
                          className="w-[40%] sm:w-[40%] md:w-auto h-[46px] mx-[10px] my-2  rounded-[35px] mt-[10px] md:mt-0 text-center"
                          size="sm"
                          outline
                          onClick={() => {
                            console.log("Button clicked with topic:", topic);
                            filterBlogsByTopic(topic);
                          }} // Add onClick event to filter blogs by topic
                        >
                          {topic}
                        </Button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* next and previous button */}
      <div className="sm:w-[30%] w-full mx-auto flex gap-2 justify-center">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          className={`mx-auto sm:w-[150px] w-fit bg-[#1e3a2b] ${
            currentPage === 1
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-[#397153] hover:cursor-pointer"
          } text-center text-white px-2  rounded-lg`}
          disabled={currentPage === 1}
        >
          Previous Page
        </button>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          className={`mx-auto sm:w-[150px] w-fit  bg-[#1e3a2b] ${
            disabled
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-[#397153] hover:cursor-pointer"
          } text-center text-white px-4 rounded-lg`}
          disabled={disabled}
        >
          Next Page
        </button>
      </div>

      <FAQ />
      <LazyLoadComponent>
        <Discussion />
      </LazyLoadComponent>
    </>
  );
}
