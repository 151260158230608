import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Img from "../img/logInImage.jpg";
import { Form, FormGroup, Button, Input, Card, CardBody } from "reactstrap";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1);

  const navigate = useNavigate();

  const handleEmailSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/sendresetotp",
        { email }
      );
      Swal.fire({
        position: "center",
        icon: "success",
        title: response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
      setStep(2); // Move to the OTP input step
    } catch (error) {
        const errorMessage = error.response?.data?.message || "Error resetting password. Please try again.";
      Swal.fire({
        position: "center",
        icon: "error",
        title: errorMessage,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Passwords do not match!",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/verifyresetotp",
        {
          email,
          otp,
          newPassword,
        }
      );
      Swal.fire({
        position: "center",
        icon: "success",
        title: response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/"); // Redirect to login page
    } catch (error) {
        const errorMessage = error.response?.data?.message || "Error resetting password. Please try again.";
      Swal.fire({
        position: "center",
        icon: "error",
        title: errorMessage,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <div className="flex" style={{ height: "100vh" }}>
      {/* Left side - Card with image and description */}
      <div className="flex-1 bg-[#1c4532] xl:block hidden">
        <div>
          <Card className="w-[27vw] h-[50vh] bg-white relative top-[7vh] left-[12vw] flex rounded items-center justify-center mt-10 mb-50px">
            <img alt="Sample" src={Img} className="p-[2px]" />
            <CardBody>
              <div className="text-[1vw] h-[15vh] text-[#1c4532] font-bold overflow-hidden">
                We at Maiti Labs believe that climate change is a pressing issue and making people aware of it is our core philosophy.
              </div>
            </CardBody>
          </Card>

          <div className="relative top-[3vh] left-[25vh] text-white text-[2vw] font-bold">
            Introducing new features
          </div>

          <div className="flex items-center justify-center h-[100%]">
            <div className="text-[#7d948f] text-[13px] m-4 w-[21vw] h-[17vh] text-center overflow-hidden">
              Our Team is working on technology tools to address climate change like carbon credit software & data compression models using AI.
            </div>
          </div>
        </div>
      </div>

      {/* Right side - Form for Reset Password */}
      <div className="flex-1 md:bg-white lg:bg-[#f8f4f0] sm:bg-[#f8f4f0] bg-[#f8f4f0] flex items-center justify-center">
        <div className="w-full max-w-md p-8 space-y-8 bg-white rounded shadow-md">
          {step === 1 ? (
            <Form onSubmit={handleEmailSubmit}>
              <h2 className="text-2xl font-bold text-center">Reset Password</h2>
              <FormGroup className="mt-4">
                <label className="text-sm font-medium text-gray-700">
                  Enter your email
                </label>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-2 mt-1 border rounded-md focus:ring focus:ring-opacity-50 focus:ring-green-400"
                  required
                />
              </FormGroup>
              <Button
                type="submit"
                className="w-full px-4 py-2 mt-6 font-bold text-white bg-[#1c4532] rounded hover:bg-green-600"
                block
              >
                Send OTP
              </Button>
            </Form>
          ) : (
            <Form onSubmit={handlePasswordReset}>
              <h2 className="text-2xl font-bold text-center">Enter OTP & Reset Password</h2>
              <FormGroup className="mt-4">
                <label className="text-sm font-medium text-gray-700">
                  OTP
                </label>
                <Input
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  className="w-full px-3 py-2 mt-1 border rounded-md focus:ring focus:ring-opacity-50 focus:ring-green-400"
                  required
                />
              </FormGroup>
              <FormGroup className="mt-4">
                <label className="text-sm font-medium text-gray-700">
                  New Password
                </label>
                <Input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full px-3 py-2 mt-1 border rounded-md focus:ring focus:ring-opacity-50 focus:ring-green-400"
                  required
                />
              </FormGroup>
              <FormGroup className="mt-4">
                <label className="text-sm font-medium text-gray-700">
                  Confirm New Password
                </label>
                <Input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full px-3 py-2 mt-1 border rounded-md focus:ring focus:ring-opacity-50 focus:ring-green-400"
                  required
                />
              </FormGroup>
              <Button
                type="submit"
                className="w-full px-4 py-2 mt-6 font-bold text-white bg-[#1c4532] rounded hover:bg-green-600"
                block
              >
                Reset Password
              </Button>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;