import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import gmail from "../assets/logo/gmail.png";
import twitter from "../assets/logo/twitter.png";

function BlogDetail() {
  const { id } = useParams();
  const [blog, setBlog] = useState({});
  const location = useLocation();
  const blogData = location.state;
  const [loading, setLoading] = useState(true); // Loading state
  const [positionIndex, setPositionIndex] = useState(0); // Index to track current position
  const positions = ["left", "right", "top"]; // Define the order of positions

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        if(blogData){
          setBlog(blogData);
        }else{
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/blog/${id}`
          );
          setBlog(response.data);
        }
        setLoading(false); // Data is loaded

        // Update positionIndex based on some criteria
        const newIndex = Math.floor(Math.random() * positions.length); // Example: Random index
        setPositionIndex(newIndex);
      } catch (error) {
        console.error("Error fetching blog details:", error);
      }
    };

    fetchBlog();
  }, [id, positions.length, blogData]); // Ensure positions.length is added as a dependency

  function capitalizeWord(sentence) {
    if (!sentence) {
      return "";
    }

    return sentence
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  // Function to add space after each paragraph
  const addClassToParagraphs = (content) => {
    return content.replace(/<p>/g, '<p class="paragraph">');
  };

  // Modify the blog content if not loading
  const modifiedContent = !loading ? addClassToParagraphs(blog.content) : "";

  return (
    <div className="bg-[#FAFAFA] shadow-md rounded-lg overflow-hidden mx-auto w-4/5 p-6 mt-2 ">
      <div className="text-center text-3xl font-[Gilroy-Bold] text-[#333333]  mb-6 ">
        {loading ? (
          <div className="h-10 bg-gray-300 rounded animate-pulse"></div>
        ) : (
          blog.title
        )}
      </div>
      <div className="text-lg text-gray-700 leading-relaxed">
        {loading ? (
          <div className="w-full h-64 bg-gray-300 rounded animate-pulse mb-4"></div>
        ) : (
          <img
            src={blog.image}
            alt={blog.title}
            className={`w-full md:max-w-[300px] lg:max-w-[400px] md:min-w-[150px] lg:min-w-[200px] rounded-lg shadow-md  mb-2 h-auto min-h-[150px] md:min-h-[150px] lg:min-h-[250px] ${
              positions[positionIndex] === "left" ? "float-left mr-3" : ""
            } ${
              positions[positionIndex] === "right" ? "float-right ml-3" : ""
            } ${
              positions[positionIndex] === "top" ? "mx-auto mb-2 block" : ""
            }`}
          />
        )}
        {loading ? (
          <div>
            <div className="h-4 bg-gray-300 mb-2 rounded animate-pulse w-3/4"></div>
            <div className="h-4 bg-gray-300 mb-2 rounded animate-pulse w-full"></div>
            <div className="h-4 bg-gray-300 mb-2 rounded animate-pulse w-5/6"></div>
          </div>
        ) : (
          <div
            className="font-[Gilroy-Medium] md:text-[23px] text-[18px] text-justify"
            dangerouslySetInnerHTML={{ __html: modifiedContent }}
          ></div>
        )}
      </div>
      <div className="text-sm text-gray-600 mt-6">
        <strong className="font-[Gilroy-Bold] text-[#333333] mr-1">
          Author:
        </strong>
        {loading ? (
          <div className="h-4 font-[Gilroy-Medium] md:text-[22px] text-[18px] bg-gray-300 rounded animate-pulse w-1/4 inline-block"></div>
        ) : (
          capitalizeWord(blog.author)
        )}
      </div>
      <div className="text-sm text-gray-600 mt-6">
        <strong className="font-[Gilroy-Bold] text-[#333333] mr-1">
          Contact:
        </strong>
        {loading ? (
          <div className="h-4 font-[Gilroy-Medium] md:text-[22px] text-[18px] bg-gray-300 rounded animate-pulse w-1/4 inline-block"></div>
        ) : (
          <>
            <a
              href="mailto:labsmaiti@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block pr-3"
            >
              <img
                src={gmail}
                alt="gmail"
                className="w-5 h-5 ml-[5px] mt-[-5px] inline-block"
              />
            </a>
            {blog.twitter ? (
              <a
                href={blog.twitter}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block"
              >
                <img
                  src={twitter}
                  alt="twitter"
                  className="w-5 h-5 ml-[5px] mt-[-5px] inline-block"
                />
              </a>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default BlogDetail;
