import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [authUser, setAuthUser] = useState(null);

  return (
    <AppContext.Provider value={{isUserLoggedIn,setIsUserLoggedIn,authUser,setAuthUser }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};