import axios from "axios";

const getRegion = async (lat, lon) => {
  try {
    const response = await axios.get(
      `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`
    );
    const address = response.data.address;
    return address.country_code.toUpperCase();
  } catch (error) {
    console.error(error);
    return null;
  }
};

const GetLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          const data = await getRegion(lat, lon);
          console.log(data);
          if (data === "US") {
            resolve(["US"]);
          } else {
            resolve(["US", data]);
          }
        },
        (error) => {
          console.error(error);
          reject(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
};

export default GetLocation;
