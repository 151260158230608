import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import React from 'react';
import { useAppContext } from '../appContext';  // Adjust the import path as necessary
import { useNavigate } from "react-router-dom";
import { FaGoogle } from 'react-icons/fa'; // Import Google icon
import axios from 'axios';  // Import axios for making HTTP requests

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyABl3dUcn3-kXH_m-pRYVXfx0FRhKIvZU8",
  authDomain: "vriksh-cd382.firebaseapp.com",
  projectId: "vriksh-cd382",
  storageBucket: "vriksh-cd382.appspot.com",
  messagingSenderId: "835242041280",
  appId: "1:835242041280:web:6f7feb4d8af61c1e96f225",
  measurementId: "G-8N146NF7H6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

const SignInGoogle = ({ mode }) => {
  const { setIsUserLoggedIn, setAuthUser } = useAppContext();
  const navigate = useNavigate(); // Get the navigate function

  const handleGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        console.log('User Info:', user);

        try {
          // Send user data to the backend to generate JWT
          console.log('Sending user data to the backend...');
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/googlesignin`, {
            name: user.displayName,
            email: user.email,
          });

          // Extract the token and user from the backend response
          const { token, user: backendUser } = response.data;
          console.log('Token:', token);
          console.log('User:', backendUser);
          
          // Set user details and token in local storage
          localStorage.setItem('authUser', JSON.stringify(backendUser));
          localStorage.setItem('authToken', `Bearer ${token}`);

          // Update context state
          setAuthUser(backendUser);
          setIsUserLoggedIn(true);
          console.log('User logged in successfully');

          // Redirect to home page
          navigate('/'); // Change the route to the home page
        } catch (error) {
          console.error('Error during sign in:', error.response.data);
        }
      })
      .catch((error) => {
        console.error('Error during sign in:', error.code, error.message, error.customData.email);
      });
  };

  return (
    <div className="flex m-0 bg-gray-100 ">
      <button
        onClick={handleGoogleSignIn}
        className="flex items-center justify-center btn btn-success btn-block color rounded-xl text-white bg-[#1c4532] w-full "
      >
        <FaGoogle className="w-5 h-5 mr-2" />
        {mode === 'signUp' ? 'Sign up with Google' : 'Sign in with Google'}
      </button>
    </div>
  );
};

export default SignInGoogle;
