import React from "react";
import { Link } from "react-router-dom";
import DecarbonnLogo from "../Images/deCarbonn.png";

const url = "https://calendly.com/x0aashish/30minutes?month=2024-03";

function DecarbonnHeader() {
  return (
    <header className="flex md:p-[45px] justify-between items-center sticky top-0 md:h-[100px] h-[80px] z-10  bg-[#1e3a2b]">
      <div className="flex items-center md:ml-10 ml-2 ">
        <img
          src={DecarbonnLogo}
          alt=""
          className="md:w-[50px] md:h-[50px] md:mr-2 md:ml-2 w-[50px] h-[50px]"
        />
        <div className="font-sans md:m-0 ml-4">
          <p className="md:text-5xl text-xl font-bold text-white">DeCarbonn</p>
        </div>
      </div>
      <div className="md:flex items-center mr-10 hidden ">
        <Link to="/About" className="sm:mr-4 m-2">
          <button className="group relative  w-24 h-10 text-lg text-white font-bold ">
            Home
          </button>
        </Link>
        <Link to="/About" className="sm:mr-4 m-2">
          <button className="group relative  w-24 h-10 text-lg font-bold  text-white">
            Projects
          </button>
        </Link>
        <Link to="/About" className="sm:mr-4 m-2">
          <button className="group relative  w-24 h-10 text-lg font-bold  text-white">
            About
          </button>
        </Link>
        <DrawOutlineButton
          onClick={() => window.open(url, "_blank")}
          className="sm:mr-4 m-2 ml-[20px]"
        >
          <p className="text-lg text-black font-bold ">Get In Touch -&gt;</p>
        </DrawOutlineButton>
      </div>
    </header>
  );
}

const DrawOutlineButton = ({ children, ...rest }) => {
  return (
    <button
      {...rest}
      className="group relative font-medium  w-[160px] rounded-lg bg-white h-10 text-lg"
    >
      <span>{children}</span>
    </button>
  );
};

export default DecarbonnHeader;
