import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function useBlogQueries() {
    const storedToken = localStorage.getItem("authToken")
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const query = useQuery({
        queryKey: ["blogs"],
        queryFn: async () => {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/showBlogs`,
            {
              headers: {
                Authorization: storedToken,
              },
            }
          );
          return response.data.blogs;
        },
        refetchOnMount: false,
      });

      const mutation = useMutation({
        mutationFn: async (data, setIsUserLoggedIn) => {
          const token = localStorage.getItem("authToken");
          if (!token) {
            setIsUserLoggedIn(false);
            alert("Retry login!");
            navigate("/login");
            return;
          }
    
          const response = await axios.post(
            process.env.REACT_APP_API_URL + "/addBlog",
            data,
            {
              headers: {
                Authorization: storedToken, // Add the "Authorization" header like this
              },
            }
          );
          console.log(response);
          if (response.status !== 201) {
            throw new Error("Blog post creation failed");
          }
      
          return response.data;
        },
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["blogs"]});
          alert("Blog post created successfully");
        },
        onError: (error) => {
          if (error.response.status === 401) {
            alert("You are Unauthorised, login again!");
            navigate("/login");
          }
          console.error("An error occurred:", error);
        }
      })

      return {query, mutation};
}
