import React from "react";
import { RxInstagramLogo } from "react-icons/rx";
import { FaLinkedin } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";

import DecarbonnLogo from "../Images/deCarbonn.png";

const DecarbonnFooter = () => {
  return (
    <footer className="bg-[#1e3a2b] p-4">
      <div className="mt-[80px] mx-auto lg:w-[100%] lg:flex block  gap-24 ">
        {/* 1st div */}
        <div className="lg:w-[100%] w-[100%] lg:mx-[52px] mx-auto ">
          <div className="flex-col mx-auto lg:w-[100%] mt-[20px] items-start  lg:h-[124px] lg:text-[30px] text-[20px]  flex  justify-center leading-[140.63%]">
            {/* image goes here */}
            <div className="flex items-start md:ml-10 mb-5 md:mt-5">
              <img
                src={DecarbonnLogo}
                alt=""
                className="md:w-[50px] md:h-[50px] md:mr-2 md:ml-2 w-[50px] h-[50px]"
              />
              <div className="font-sans md:m-0 ml-4">
                <p className="md:text-4xl text-3xl font-bold text-white">
                  DeCarbonn
                </p>
              </div>
            </div>

            <p className="font-[Gilroy-Light] text-2xl font-bold text-white text-left md:ml-10 mt-5 md:mb-5">
              {" "}
              Have Any Query ?
            </p>
            {/* </div> */}

            <div className="lg:flex  block text-xl justify-center md:ml-10 mb-5">
              <input
                type="text"
                className="p-2 border h-[60%]  w-[100%] border-gray-400 bg-white rounded  lg:mr-4 mt-2"
                placeholder="Email"
              />
              <button className=" w-full h-[50px] lg:h-[60%] lg:w-[50%] mt-2  text-white  bg-black rounded-md">
                Send
              </button>
            </div>
          </div>
        </div>
        {/* 2nd part */}
        <div className="md:flex md:flex-nowrap flex flex-wrap  gap-5 xl:gap-5 justify-center mr-[13px] lg:w-[100%] w-[100%] lg:ml-[106px] mx-auto my-[46px]">
          {/* social div */}
          <div className="mt-[23px] mb-[23px] w-80% mx-auto font-Gilroy-light leading-1.2em text-left text-white ">
            SOCIAL
            <div className="mt-[24px] text-base   ">
              <ul>
                <li>
                  {" "}
                  <a
                    className="block mt-24px hover:text-[#8f9b95] mt-[23px]"
                    href="https://www.instagram.com/maitilabs?igsh=MWF0c2J3eXdvNmFhMQ=="
                  >
                    <RxInstagramLogo className="inline mr-2 " />
                    Instagram
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    className="block mt-24px hover:text-[#8f9b95] mt-[12px]"
                    href="https://www.linkedin.com/company/maitilabs/"
                  >
                    <FaLinkedin className="inline mr-2" />
                    Linkedin
                  </a>
                </li>
                <li>
                  <a
                    className="block mt-24px hover:text-[#8f9b95] mt-[12px]"
                    href="/"
                  >
                    <FaTwitter className="inline mr-2" />
                    Twitter
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* company */}
          <div className="mt-[23px] mb-[23px] w-80% mx-auto font-Gilroy-light  leading-1.2em text-left text-white ">
            COMPANY
            <div className="mt-[24px] text-base text-left  ">
              <ul>
                <li>
                  {" "}
                  <a
                    className="block mt-24px hover:text-[#8f9b95] mt-[23px]"
                    href="/"
                  >
                    Home
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    className="block mt-24px hover:text-[#8f9b95] mt-[12px]"
                    href="/About"
                  >
                    Project
                  </a>
                </li>
                <li>
                  <a
                    className="block mt-24px hover:text-[#8f9b95] mt-[12px]"
                    href="/"
                  >
                    About
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* initiatives */}
          <div className="mt-[23px] mb-[23px] w-80% mx-auto font-Gilroy-light leading-1.2em text-left text-white ">
            INITIATIVES
            <div className="mt-[24px] text-base text-left  ">
              <ul>
                <li>
                  {" "}
                  <a
                    className="block mt-24px hover:text-[#8f9b95] mt-[23px]"
                    href="https://maitilabs.org/"
                  >
                    Maiti Labs
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    className="block mt-24px hover:text-[#8f9b95] mt-[12px]"
                    href="/DeCarbonn"
                  >
                    DeCarbonn
                  </a>
                </li>
                <li>
                  <a
                    className="block mt-24px hover:text-[#8f9b95] mt-[12px]"
                    href="/GreenBit"
                  >
                    GreenBit
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* last part  */}
      <div className="text-white text-center text-xl lg:text-4xl">
        Made With ❤️ At Maiti Labs
      </div>
      <div className="text-white text-center text-xs lg:mt-3 mt-2">
        copyright © 2024 DeCarbonn
      </div>
    </footer>
  );
};

export default DecarbonnFooter;
