
import Image from "../assets/image 13.png";
import Image2 from "../assets/image 7.png";
import Image3 from "../assets/image 8.png";
import Image4 from "../assets/image 9.png";
import Image5 from "../assets/image 10.png";
import Image6 from "../assets/image 12.png";

export const CompletedProjects = () => {
  const completedProjects = [
    {
      body: 'A cycling event organized by the Native Green Foundation and Samayu in Chandigarh on October 16, 2022, promoted pollution-free transportation and a healthy lifestyle. The event featured "plogging," where 169 participants collected waste and disposed of it properly, fostering environmental responsibility.',
      image: Image,
    },
    {
      body: 'On June 10, 2023, the Native Green Foundation carried out a tree plantation program at the NEMCARE Group of Institutions in Mirza, Assam, India. We planted 20 numbers of plant saplings, comprising 10 "Neem" (Azadirachta indica) saplings and 10 "Krishnashura" (Delonix regia) saplings, within the premises of the NEMCARE Group of Institutions.',
      image: Image2,
    },
    {
      body: "A tree plantation program at Barsojai  High School, Guwahati, resulted in 103 saplings being planted on the school campus, supported by the Guwahati Social Forestry Division. The school was chosen for its fertile soil and large, undeveloped area, making it ideal for plantation. The initiative aimed to raise environmental awareness and responsibility among students.",
      image: Image3,
    },
    {
      body: "Plantation of trees in the community lands of the villages of Kalyanpura, Basera, Jamli, Samplipathar, Borankheda Kundal ganava, Kaliyakund, Surajpura, Kataro ki bhi, Khadiyavani, Belari, Kherot, and Kerwas at the fringes of Sitamata Wildlife Sanctuary in Pratapgarh, Rajasthan, India.",
      image: Image4,
    },
    {
      body: "Green India Trust (GIT), founded in 2008, focuses on improving livelihoods through better use of land, water, and forest resources, supported by value chain expansion and financial access. It promotes community health, sanitation, and immunization, while also addressing skill development, women empowerment, and job creation through targeted training and support.",
      image: Image5,
    },
    {
      body: "On June 10, 2023, the Native Green Foundation planted 20 saplings—10 Neem and 10 Krishnashura—at the NEMCARE Group of Institutions in Mirza, Assam. The event was a success thanks to the cooperation of NGO members, institution staff, and special support from Dr. Apurba Talukdar, Deputy Registrar of Academics.",
      image: Image6,
    },
  ];

  return (
    <>
      <div className="relative">
        <div className="text-[#1E3A2B] text-center leading-[72px] font-bold text-5xl">
          Completed Projects
        </div>
        <div className="container mx-auto px-4">
      {completedProjects.map((project, index) => (
        <div key={index} className="flex flex-col lg:flex-row items-center justify-between my-20 lg:my-40">
          <div className={`w-full lg:w-1/2 ${index % 2 === 0 ? 'lg:order-1' : 'lg:order-2'}`}>
            <div className="relative w-full max-w-[869px] mx-auto">
              <div className="absolute border-1 border-white inset-0 bg-[#1E3A2B] rounded-2xl transform -translate-x-4 translate-y-4"></div>
              <div className="absolute border-1 border inset-0 bg-[#1E3A2B] rounded-2xl transform -translate-x-2 translate-y-2"></div>
              <div className="relative border-1 border bg-[#1E3A2B] rounded-2xl p-4 sm:p-8">
                <div className="mb-4">
                  <img src={project.image} alt={project.title} className="w-full h-auto rounded-lg" />
                </div>
                <div className="space-y-4">
                  <button className="w-full py-3 bg-white text-black font-bold rounded-lg hover:bg-gray-100 transition-colors">
                    Know More
                  </button>
                  <button className="w-full py-3 bg-white text-black font-bold rounded-lg hover:bg-gray-100 transition-colors">
                    Highlights
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={`w-full lg:w-1/2 mt-8 lg:mt-0 ${index % 2 === 0 ? 'lg:order-2' : 'lg:order-1'}`}>
            <p className={`text-lg sm:text-2xl xl:!leading-10 xl:text-[28px] p-8 font-medium ${index % 2 === 0 ? 'lg:text-right' : 'lg:text-left'}`}>
              {project.body}
            </p>
          </div>
        </div>
      ))}
    </div>
      </div>
    </>
  );
};