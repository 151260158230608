import React from "react";
import DecarbonnHeader from "./DecabonnHeader";
import DecarbonnFooter from "./DecarbonnFooter";
import img1 from "../Images/media.png";
import img2 from "../Images/_868703d6-8965-4078-87e2-ce85f4bc20d1.jpg";
import img3 from "../Images/precise tracking.jpg";
import p1 from "../Images/p1.jpg";
import p2 from "../Images/p2.jpg";
import p3 from "../Images/p3.jpg";
import p4 from "../Images/p4.jpg";
const url = "https://calendly.com/x0aashish/30minutes?month=2024-03";

const Decarbonn = () => {
  return (
    <>
      <DecarbonnHeader />
      <div className="p-0 m-0 overflow-hidden">
        <div className="p-0 m-0">
          {/* home page */}
          <div>
            <div className="cursor-default mx-auto w-auto " id="home">
              <p className="text-[#1E3A2B] text-center mx-auto md:mt-[115px] mt-[60px]  md:text-[55px] md:w-[1000px] w-[380px] text-[28px] leading-[1.4] p-[30px] font-[Gilroy-XBold]">
                DeCarbonn your business by investing in carbon offset projects.
              </p>
              <p className="mx-auto md:mt-[40px] mt-[9px] text-center font-[Gilroy-Light] md:text-[30px] text-[18px] leading-[1.4] font-light text-[#1E3A2B] md:w-[589px] w-full h-auto ">
                We search in India for climate projects with the highest impact
              </p>
              <div className="flex gap-3 justify-evenly md:justify-around md:w-[540px] w-[65%] md:text-[18px] text-[15px] h-[112px] md:mt-[20px] mx-auto">
                <DrawOutlineButton
                  onClick={() => window.open(url, "_blank")}
                  name="projects"
                >
                  <p className="transition-transform transform scale-105 group-hover:scale-100">
                    Projects
                  </p>
                </DrawOutlineButton>

                <DrawOutlineButton2
                  name="getintouch"
                  onClick={() => window.open(url, "_blank")}
                >
                  <p className="transition-transform transform scale-105 group-hover:scale-100 ">
                    Get In Touch
                  </p>
                </DrawOutlineButton2>
              </div>
            </div>
          </div>
          {/* video here */}
          <div></div>
          {/* goals */}
          <div id="about">
            {" "}
            <div className="md:w-auto w-auto md:mx-auto mx-auto">
              <div className="cursor-default mt-[54px] w-full  mx-auto text-center text-[30px] md:text-[40px] text-[#1E3A2B] font-[Gilroy-Bold] md:pt-[30px] pt-[15px]">
                Our goal is to simplify & enhance climate action.
              </div>
              {/* right image */}
              <div className="mx-auto mt-[69px] md:flex block w-[90%] justify-between">
                <div className="md:hidden flex md:w-[630px] w-[auto] my-[20px] mx-auto">
                  <img
                    className="rounded-[20px]"
                    src={img1}
                    alt="image2 not found"
                  />
                </div>
                <div className="md:w-[50%] md:h-[auto] h-full my-auto mx-auto md:mr-[37px] w-[auto]">
                  <h1 className="text-[#1E3A2B] md:text-[42px] text-[38px] mt-[34px] font-[Gilroy-XBold] text-left">
                    Calculate your Carbonn footprints
                  </h1>
                  <p className="font-[Gilroy-Light] md:text-[22px] md:w-[500px] mt-2 text-[16px] text-left">
                    Use our open source calculator to find out how much CO2 your
                    business emits.
                  </p>
                  <DrawOutlineButton
                    // onClick={() => window.open(url, "_blank")}
                    name="calculator"
                  >
                    <p className="transition-transform transform scale-105 group-hover:scale-100">
                      Calculator
                    </p>
                  </DrawOutlineButton>
                </div>
                <div className="md:flex hidden md:w-[630px] w-[auto] my-[20px] mx-auto">
                  <img
                    className="rounded-[20px]"
                    src={img1}
                    alt="image2 not found"
                  />
                </div>
              </div>
              {/* left image */}
              <div className="mx-auto mt-[69px] md:flex block w-[90%]">
                <div className="md:w-[630px] w-[auto] my-[20px] mx-auto">
                  <img
                    className=" rounded-[20px] md:w-[630px] md:h-[400px] w-[500px] h-[220px]"
                    src={img2}
                    alt="image1 not found"
                  />
                </div>
                <div className="md:w-[50%] md:h-[auto] h-full my-auto md:ml-[37px] w-[auto]">
                  <h1 className="text-[#1E3A2B] md:text-[42px] text-[38px] mt-[34px] font-[Gilroy-XBold] text-left">
                    Connect with a Projects and Invest.
                  </h1>
                  <p className="font-[Gilroy-Light] mt-2 md:text-[22px] text-[16px] md:w-[500px] text-left">
                    Choose a project and we'll connect you with the on-ground
                    team before you invest.
                  </p>
                  <DrawOutlineButton
                    // onClick={() => window.open(url, "_blank")}
                    name="projects"
                  >
                    <p className="transition-transform transform scale-105 group-hover:scale-100">
                      Projects
                    </p>
                  </DrawOutlineButton>
                </div>
              </div>
              {/* right image */}
              <div className="mx-auto mt-[69px] md:flex block w-[90%] justify-between">
                <div className="md:hidden flex md:w-[630px] w-[auto] my-[20px] mx-auto">
                  <img
                    className="rounded-[20px]"
                    src={img3}
                    alt="image2 not found"
                  />
                </div>
                <div className="md:w-[50%] md:h-[auto] h-full my-auto mx-auto  md:mr-[37px] w-[auto]">
                  <h1 className="text-[#1E3A2B] md:text-[42px] text-[38px] mt-[34px] font-[Gilroy-XBold] text-left">
                    Earn Credits and Track your investments
                  </h1>
                  <p className="font-[Gilroy-Light] md:text-[22px] mt-2 text-[16px] md:w-[500px] text-left">
                    Receive your carbon credits in your wallet and track your
                    investments impact every month.
                  </p>
                </div>
                <div className="md:flex hidden md:w-[630px] w-[auto] my-[20px] mx-auto">
                  <img
                    className="rounded-[20px]"
                    src={img3}
                    alt="image2 not found"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* projects */}
          <div id="projects" class="flex flex-wrap justify-evenly md:mt-20">
            <div className="cursor-default mt-[54px] w-full  mx-auto text-center text-[30px] md:text-[40px] text-[#1E3A2B] font-[Gilroy-Bold] md:pt-[30px] pt-[15px] mb-20">
              All DeCarbonn projects meet these rigorous criteria:
            </div>
            {/* 1st */}

            <div class="w-full md:w-1/5 lg:w-1/5 m-3 md:ml-8">
              <div class="w-fit bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg h-52 w-full" src={p1} alt="" />
                <div class="p-4">
                  <h5 class="mb-2 text-2xl font-[Gilroy-XBold] tracking-tight text-gray-900 ">
                    Precise Tracking
                  </h5>
                  <p class="mb-3 font-[Gilroy-Light] text-gray-700 dark:text-gray-400  text-justify">
                    We carefully check how much CO₂ is offset by each project,
                    so we can promise you lasting impact.
                  </p>
                </div>
              </div>
            </div>
            {/* 2nd */}
            <div class="w-full md:w-1/5 lg:w-1/5 m-3 md:mb-0">
              <div class="w-fit bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg h-52 w-full" src={p2} alt="" />
                <div class="p-2">
                  <h5 class="mb-2 ml-2 text-2xl font-[Gilroy-XBold] tracking-tight text-gray-900 ">
                    Every dollar makes a difference
                  </h5>
                  <p class="mb-3 m-2 font-[Gilroy-Light] text-gray-700 dark:text-gray-400 text-justify">
                    Our projects wouldn't happen without your support. With
                    DeCarbonn, your money makes a real impact.
                  </p>
                </div>
              </div>
            </div>
            {/* 3rd */}
            <div class="w-full md:w-1/5 lg:w-1/5 m-3 md:mb-0">
              <div class="w-fit bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg h-52 w-full" src={p3} alt="" />
                <div class="p-[7px]">
                  <h5 class="mb-2 ml-2 text-2xl font-[Gilroy-XBold] tracking-tight text-gray-900 ">
                    Internationally Recognized Credits Issuer
                  </h5>
                  <p class="mb-3 m-2 font-[Gilroy-Light] text-gray-700 dark:text-gray-400  text-justify">
                    We make sure all our projects are vetted by Internationally
                    recognized issuers like Verra, Gold Standard, CSI etc
                  </p>
                </div>
              </div>
            </div>
            {/* 4th */}
            <div class="w-full md:w-1/5 lg:w-1/5 m-3 md:mb-0">
              <div class="w-fit bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg h-52 w-full" src={p4} alt="" />
                <div class="p-4">
                  <h5 class="mb-2 text-2xl font-[Gilroy-XBold] tracking-tight text-gray-900 ">
                    Regular Updates
                  </h5>
                  <p class="mb-3 font-[Gilroy-Light] text-gray-700 dark:text-gray-400  text-justify">
                    We get data straight from projects on the ground, so you can
                    track your impact, month by month.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* faq section*/}

          <div class="container mx-auto py-12 mt-5">
            <div class="max-w-4xl mx-auto">
              <h2 class="text-3xl font-bold text-center mb-8">
                Frequently Asked Questions
              </h2>

              <div class="border-t border-gray-300">
                {/* <!-- FAQ Item 1 --> */}
                <div class="py-6 border-b border-gray-300">
                  <details>
                    <summary class="flex items-center justify-between w-full px-4 py-2 text-left focus:outline-none">
                      <span class="text-lg font-semibold">
                        How do I invest in a Project?
                      </span>
                      <svg
                        class="w-4 h-4 text-gray-500 transform transition-transform duration-300"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M19 9l-7 7-7-7" />
                      </svg>
                    </summary>
                    <div class="px-4 pt-4">
                      <p class="text-gray-600 text-justify">
                        When you want to invest in a project, click on the
                        Invest Now button and write to us on how much investment
                        you want to make. We will get back to you within 48
                        hours to understand your requirements. Shortly after
                        that, we will connect you with the on-ground team and
                        you can invest in the project.
                      </p>
                    </div>
                  </details>
                </div>

                {/* <!-- FAQ Item 2 --> */}
                <div class="py-6 border-b border-gray-300">
                  <details>
                    <summary class="flex items-center justify-between w-full px-4 py-2 text-left focus:outline-none">
                      <span class="text-lg font-semibold">
                        Can I get more details on the project than already on
                        the site?
                      </span>
                      <svg
                        class="w-4 h-4 text-gray-500 transform transition-transform duration-300"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M19 9l-7 7-7-7" />
                      </svg>
                    </summary>
                    <div class="px-4 pt-4">
                      <p class="text-gray-600">
                        Yes, you can write to us and we will be able to connect
                        you with the on-ground operations team of the project.
                        They would be able to give you a detailed explanation of
                        the project.
                      </p>
                    </div>
                  </details>
                </div>

                {/* <!-- FAQ Item 3--> */}

                <div class="py-6 border-b border-gray-300">
                  <details>
                    <summary class="flex items-center justify-between w-full px-4 py-2 text-left focus:outline-none">
                      <span class="text-lg font-semibold">
                        When will I receive my Carbon Credits in my account?
                      </span>
                      <svg
                        class="w-4 h-4 text-gray-500 transform transition-transform duration-300"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M19 9l-7 7-7-7" />
                      </svg>
                    </summary>
                    <div class="px-4 pt-4">
                      <p class="text-gray-600">
                        <ul className="list-disc">
                          <li>
                            {" "}
                            For Ex-post Carbon Credit, the credits will be
                            transferred to your account within 72 hours of the
                            payment received.
                          </li>
                          <li>
                            For Ex-ante Carbon Credits, an agreement will be
                            signed and the credits will be made available to you
                            on the completion of the project.
                          </li>
                        </ul>
                      </p>
                    </div>
                  </details>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DecarbonnFooter />
      </div>
    </>
  );
};

const DrawOutlineButton = ({ children, ...rest }) => {
  return (
    <button
      {...rest}
      className="group relative font-mediumcursor-pointer w-[190px] my-[32px] h-[40px] delay-50 rounded-lg bg-[#1e3a2b] hover:bg-slate-100/50 hover:shadow-md hover:shadow-emerald-800/50 text-slate-100 transition-colors duration-[300ms] hover:text-[#1e3a2b] hover:font-semibold transform scale-100 group-hover:scale-110"
    >
      <span>{children}</span>
    </button>
  );
};

const DrawOutlineButton2 = ({ children, ...rest }) => {
  return (
    <button
      {...rest}
      className="group relative font-mediumcursor-pointer w-[190px] my-[32px] h-[40px] delay-50 rounded-lg border-[1px] border-green-900 text-[#1e3a2b] hover:bg-[#1e3a2b] hover:shadow-md hover:shadow-emerald-800/50  transition-colors duration-[300ms] hover:text-white hover:font-semibold transform scale-100 group-hover:scale-110"
    >
      <span>{children}</span>
    </button>
  );
};

export default Decarbonn;
