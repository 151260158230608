import useStore from "../store";

export const Vhome = () => {
  const Ref = useStore((state) => state.Ref);
  const handle = () => {
    if (Ref) {
      Ref.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div id='home' className="bg-green-bg min-h-screen flex flex-col items-center text-white">
      <main  className="flex flex-col items-center mt-[9rem] sm:mt-[13rem] text-center px-3 py-16">
        <h2 className="text-4xl md:text-[4rem] font-[Gilroy-Bold] mb-6 leading-tight">
          The future cant wait, Plant a Vriksh today
        </h2>
        <p className="text-[1.5rem] font-jostRegular px-[1rem] xl:px-[9.7rem] mb-8">
          Trees absorb carbon dioxide, which is a greenhouse gas that
          contributes to climate change and releases oxygen which we need to
          live.
        </p>
        <div className="space-x-0 mb-[6rem] lg:mb-[6rem] md:mb-[4rem] flex flex-col md:flex-row items-center md:space-x-10 space-y-4 md:space-y-0"
          onClick={handle}
        >
           <DrawOutlineButton2
          > 
            Plant Now
          </DrawOutlineButton2>
        </div>
      </main>
    </div>
  );
};


const DrawOutlineButton2 = ({ children, ...rest }) => {
  return (
    <button
      {...rest}
      className="font-mediumcursor-pointer bg-[#ffffff] text-green-bg mb-1 w-[14.25rem] font-jostSemiBold font-semibold text-[1.5rem] md:text-[2rem] px-6 py-[0.7rem] rounded-[1rem] hover:shadow-md hover:shadow-white hover:text-[#ffffff] hover:bg-[#1e3a2b] hover:-translate-y-1 hover:scale-105 transition-colors duration-[300ms]"
    >
      <span>{children}</span>
    </button>
  );
};