import { useNavigate } from "react-router-dom";
// import { useState } from "react";
import img1 from "../Images/pexels-roberto-nickson-2559941.jpg";
import img2 from "../Images/pexels-baskin-creative-studios-1766838.jpg";
import img3 from "../Images/pexels-james-wheeler-417074.jpg";
import img4 from "../Images/pexels-eberhard-grossgasteiger-572897.jpg";
import { Container, Carousel } from "react-bootstrap";
// import cr1 from "../Images/cr1.png";
import berkeley from "../Images/berkeley.png";
import cr2 from "../Images/cr2.png";
import cr3 from "../Images/cr3.png";
import cr4 from "../Images/cr4.png";
import cr5 from "../Images/cr5.png";
import cr6 from "../Images/cr6.png";
import birdvideo from "../Images/birdvideo.mp4";
import { RxInstagramLogo } from "react-icons/rx";
import { FaLinkedin } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
// import axios from "axios";

import Awareness from "../Images/Awareness.jpg";
import Img3 from "../Images/vriksh.svg";
import { ContactForm } from "./ContactForm";
import { Helmet } from "react-helmet";

function HomePage() {
  const navigate = useNavigate();
  function handleHref(e) {
    const Name = e.currentTarget.name;
    navigate("/" + Name);
  }

  const url =
    "https://www.google.com/url?q=https%3A%2F%2Fchat.whatsapp.com%2FHToo6tS5XFO0bxbcOQTdy7&sa=D&sntz=1&usg=AOvVaw2jP6yJms6M04eeE0NaLo1P"; // Replace with the actual URL

  return (
    <>
    <Helmet>
    <title>Maiti Labs</title>
    <meta name="description" content="A Non-Profit Youth-Led organization fostering Climate Action by making information accessible in every language possible." />
    <link rel="canonical" href="https://www.maitilabs.org/" />

    {/* Schema.org for Organization */}
    <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Maiti Labs",
        "url": "https://maitilabs.org",
        "logo": "https://www.maitilabs.org/static/media/Logo.00129f1c05848c338b15.png",
        "contactPoint": {
          "@type": "ContactPoint",
          "email": "labsmaiti@gmail.com",
          "url": "mailto:labsmaiti@gmail.com"
        },
        "sameAs": [
          "https://www.instagram.com/maitilabs?igsh=MWF0c2J3eXdvNmFhMQ==",
          "https://www.linkedin.com/company/maitilabs/",
          "https://x.com/MaitiLabs"
        ]
      }
    `}
    </script>
  
    {/*Schema.org for WebPage*/}
    <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Home Page",
        "description": "The homepage of Maiti Labs, a youth-led non-profit organization dedicated to climate action.",
        "url": "https://maitilabs.org",
        "mainEntity": {
          "@type": "Organization",
          "name": "Maiti Labs",
          "url": "https://maitilabs.org",
          "logo": "https://www.maitilabs.org/static/media/Logo.00129f1c05848c338b15.png"
        }
      }
    `}
    </script>
  </Helmet>
    <div className="w-full h-full">
      <div className="cursor-default mx-auto w-4/5 " id="home">
        <h1 className="text-[#1E3A2B] text-center mt-12 md:mt-[115px] md:text-[80px] text-[62px]  font-[Gilroy-XBold]">
          The Voice Of Planet Earth in Every Language!
        </h1>
        <p className="mx-auto md:mt-[40px] mt-[19px] text-center font-[Gilroy-Light] md:text-[30px] text-[25px] leading-[1.4] font-light text-[#1E3A2B] md:w-[589px] w-full h-auto ">
          Global youth-led Non-Profit Organization
        </p>
        <div className="flex justify-evenly md:justify-around md:w-[540px] w-[80%] md:text-[18px] text-[15px] h-[112px] md:mt-[20px] mx-auto">
          <DrawOutlineButton
            onClick={() => window.open(url, "_blank")}
            name="joinus"
          >
            <p className="transition-transform transform scale-105 group-hover:scale-100">
              Join Us
            </p>
          </DrawOutlineButton>
          <DrawOutlineButton2 onClick={handleHref} name="awareness">
            <p className="transition-transform transform scale-105 group-hover:scale-100">
              Awareness Portal
            </p>
          </DrawOutlineButton2>
        </div>
      </div>
      <div className="cursor-default md:w-[90%] w-full md:px-4 px-2 md:h-auto h-auto font-[Belleza] md:mt-[185px] mt-12 mx-auto ">
        <p className="md:text-[85px] text-[40px] md:leading-[100px] leading-[59px] text-center tracking-[-0.05em]">
          We are a Non-Profit Youth-Led Org fostering{" "}
          <span className="bg-[#1E3A2B] text-white">Climate Action</span> by
          making information accessible in every language possible. 🌱
        </p>
      </div>
      <section
        className="cursor-default md:w-auto w-auto md:mx-auto mx-auto mt-[101px] sm:mt-[150px] bg-white"
        id="about"
      >
        <div
          className="md:w-auto w-auto md:mx-auto mx-auto"
          style={{ backgroundColor: "#f8f4f0" }}
        >
          <div className="cursor-default mt[54px] w-[310px] h-[71px] mx-auto text-center text-[57px] text-[#1E3A2B] font-[Gilroy-Bold] md:pt-[30px] pt-[15px]">
            About Us
          </div>

          <div className="mx-auto mt-[69px] md:flex block w-[90%]">
            <div className="md:w-[630px] w-[auto] my-[20px] mx-auto">
              <img
                className=" rounded-[20px]"
                src={img1}
                alt="image1 not found"
              />
            </div>
            <div className="md:w-[50%] md:h-[auto] h-full my-auto md:ml-[37px] w-[auto]">
              <h2 className="text-[#1E3A2B] md:text-[42px] text-[38px] mt-[34px] font-[Gilroy-XBold] text-left">
                Youth-Led & Global
              </h2>
              <p className="font-[Gilroy-Light] md:text-[22px] text-[16px] text-left">
                We are a global youth-led Non-Profit Organization committed to
                tackling the pressing climate crisis through our innovative
                community-first approach.
              </p>
            </div>
          </div>

          <div className="mx-auto mt-[69px] md:flex block w-[90%] justify-between">
            <div className="md:hidden flex md:w-[630px] w-[auto] my-[20px] mx-auto">
              <img
                className="rounded-[20px]"
                src={img2}
                alt="image2 not found"
              />
            </div>
            <div className="md:w-[50%] md:h-[auto] h-full my-auto md:mr-[37px] w-[auto]">
              <h2 className="text-[#1E3A2B] md:text-[42px] text-[38px] mt-[34px] font-[Gilroy-XBold] text-left">
                Tech Driven Action
              </h2>
              <p className="font-[Gilroy-Light] md:text-[22px] text-[16px] text-left">
                Powerful technology products, specifically cutting-edge news
                sites, that empower users to raise their voices and contribute
                to climate action
              </p>
            </div>
            <div className="md:flex hidden md:w-[630px] w-[auto] my-[20px] mx-auto">
              <img
                className="rounded-[20px]"
                src={img2}
                alt="image2 not found"
              />
            </div>
          </div>
          <div className="mx-auto mt-[69px] md:flex block w-[90%]">
            <div className="md:w-[630px] w-[auto] my-[20px] mx-auto">
              <img
                className=" rounded-[20px]"
                src={img3}
                alt="image1 not found"
              />
            </div>
            <div className="md:w-[50%] md:h-[auto] h-full my-auto md:ml-[37px] w-[auto]">
              <h2 className="text-[#1E3A2B] md:text-[42px] text-[38px] mt-[34px] font-[Gilroy-XBold] text-left">
                Inclusivity through Translations
              </h2>
              <p className="font-[Gilroy-Light] md:text-[22px] text-[16px] text-left">
                Breaking down barriers through advanced artificial intelligence
                translations. Our content is accessible and readable in any
                language.
              </p>
            </div>
          </div>
          {/* products section */}
          <div className="text-center">
            <h2 className="text-[#1f3a2b] text-2xl font-bold mt-10">
              Our Initiative
            </h2>
            <div className="flex flex-col md:flex-row justify-evenly  sm:w-1/2 m-[8px] sm:mx-auto sm:h-full  h-full md:h-full p-5 bg-[#f7f1ec] rounded-xl border-2 border-[#c2bebb] mt-10">
              {/* Vertical Line */}
              <div className="hidden  md:block relative left-1/2 bg-[#c2bebb] w-0.5 h-[400px] top-2"></div>

              {/* Awareness section */}
              <div className="flex flex-col justify-center items-center h-full sm:h-auto p-5 md:w-full sm:w-full w-full">
                <img
                  src={Awareness}
                  alt="Sample"
                  className="m-[10px] rounded-xl object-cover w-full h-48 sm:h-auto"
                />
                <h3 className="text-[#1f3a2b] text-center font-bold mt-4">
                  Awareness Portal
                </h3>
                <h4 className="text-sm text-center">
                  Climate Information in Every Language
                </h4>
                <button
                  onClick={handleHref}
                  name="awareness"
                  className="text-darkblue text-sm text-blue-500 mt-2"
                >
                  Learn More &gt;
                </button>
              </div>

              {/* GreenBit section */}
              <div className="flex flex-col justify-center items-center h-full sm:h-auto p-5 md:w-full sm:w-full w-full">
                <img
                  className="m-[10px] rounded-xl object-cover w-full h-48 sm:h-auto"
                  src={Img3}
                  alt="Sample"
                />
                <h3 className="text-[#1f3a2b] font-bold mt-4">Vriksh</h3>
                <h4 className="text-sm text-center">We Plant For the Future</h4>
                <a
                  className="text-darkblue text-sm text-blue-500 mt-2"
                  href="/Vriksh"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More &gt;
                </a>
              </div>
            </div>
          </div>

          <div
            // style={{ position: "relative", top: "7vh" }}
            className="md:w-[17%] w-[30%] md:h-[58px] h-[46px] mx-auto text-center relative top-[7vh] md:mt-[77px] mt-[66px] pb-[100px]"
          >
            <DrawOutlineButton3
              className="w-[100%] h-[58px] rounded-[25px] bg-[#1E3A2B] text-[white] shadow-xl"
              onClick={handleHref}
              name="about"
            >
              <p className="transition-transform transform scale-105 group-hover:scale-100 font-medium">
                More
              </p>
            </DrawOutlineButton3>
          </div>
        </div>
      </section>

      <div className=" cursor-default bg-[#D9A673] pt-[101px]  w-[100%] h-[712px] mt-[102px]">
        <p className="mx-auto w-fit text-center font-[Gilroy-XBold] md:text-[50px] text-[40px] tracking-[-2.1px] leading-[1.2em] ">
          Our Community Members Come from ❤️
        </p>

        <Container className="md:w-[937px] w-full md:h-[300px] h-[250px] md:mt-[80px] mt-[60px]">
  <Carousel>
    <Carousel.Item className="text-center md:mt-5 mt-10 mb-10">
      <div className="inline-block md:w-[250px] w-[170px] h-[200px] rounded-[20px] overflow-hidden">
        <img
          className="w-full h-full object-contain bg-[#EFE6DC]"
          src={berkeley}
          alt="First slide"
        />
      </div>
      <div className="inline-block md:w-[250px] w-[170px] h-[200px] md:ml-3 ml-[10px] rounded-[20px] overflow-hidden">
        <img
          className="w-full h-full object-contain bg-[#B5C7A4]"
          src={cr2}
          alt="Second slide"
        />
      </div>
    </Carousel.Item>

    <Carousel.Item className="text-center md:mt-5 mt-10 mb-10">
      <div className="inline-block md:w-[250px] w-[170px] h-[200px] rounded-[20px] overflow-hidden">
        <img
          className="w-full h-full object-contain bg-[#CFDCFF]"
          src={cr3}
          alt="Third slide"
        />
      </div>
      <div className="inline-block md:w-[250px] w-[170px] h-[200px] md:ml-3 ml-[10px] rounded-[20px] overflow-hidden">
        <img
          className="w-full h-full object-contain bg-[#B5C7A4]"
          src={cr4}
          alt="Fourth slide"
        />
      </div>
    </Carousel.Item>

    <Carousel.Item className="text-center md:mt-5 mt-10 mb-10">
      <div className="inline-block md:w-[250px] w-[170px] h-[200px] rounded-[20px] overflow-hidden">
        <img
          className="w-full h-full object-contain bg-[#EFE6DC]"
          src={cr5}
          alt="Fifth slide"
        />
      </div>
      <div className="inline-block md:w-[250px] w-[170px] h-[200px] md:ml-3 ml-[10px] rounded-[20px] overflow-hidden">
        <img
          className="w-full h-full object-contain bg-[#CFDCFF]"
          src={cr6}
          alt="Sixth slide"
        />
      </div>
    </Carousel.Item>
  </Carousel>
</Container>




        <div className="flex justify-around  w-full mx-auto md:mt-[20px] mt-[280px]">
          <DrawOutlineButton5
            className=" mx-auto  rounded-[25px] bg-[#1E3A2B] text-white shadow-xl text-center px-3  py-2 focus:outline-none "
            onClick={() => window.open(url, "_blank")}
          >
            <p className="transition-transform transform scale-105 group-hover:scale-100 font-medium">
              Join Us
            </p>
          </DrawOutlineButton5>
        </div>
      </div>
      <div
        className="mt-[50px] md:w-full md:h-[600px] bg-cover bg-center backdrop-opacity-65"
        style={{ backgroundImage: `url(${img4})` }}
        id="awareness"
      >
        <div className="flex flex-col items-center justify-center my-auto">
          <div className="mt-[115px]">
            <p className="text-center text-[50px] text-white font-[Gilroy-XBold]">
              AWARENESS PORTAL
            </p>
          </div>
          <div className="text-center md:w-[50%] w-[80%] text-white font-[Gilroy-Light] ">
            <p className="mt-[20px] text-[27px] leading-[1.5em] tracking-[-0.5px]">
              We speak about important issue to address climate change.
            </p>
          </div>
          <div className=" mt-[20px] md:mb-0 mb-[64px]">
            <DrawOutlineButton6
              className="md:w-[90%] w-[150px] md:h-[50px] h-[60px] rounded-full bg-[#1E3A2B] text-[white] shadow-xl md:text-[20px] text-[20px] mb-10"
              onClick={handleHref}
              name="awareness"
            >
              <p className="transition-transform transform scale-105 group-hover:scale-100 font-medium">
                Awareness Portal
              </p>
            </DrawOutlineButton6>
          </div>
        </div>
      </div>

      <div
        className="relative md:w-full md:h-[600px] bg-cover bg-center backdrop-opacity-65"
        style={{ backgroundImage: `url(${img1})` }}
        id="career"
      >
        <div className="flex flex-col items-center justify-center my-auto">
          <div className="mt-[115px]">
            <p className="text-center text-[50px] text-white font-[Gilroy-XBold]">
              Join Us
            </p>
          </div>
          <div className="text-center md:w-[50%] w-[80%] text-white font-[Gilroy-Light] ">
            <p className="mt-[20px] text-[27px] leading-[1.5em] tracking-[-0.5px]">
              We cannot do this alone. We need your voice; your actions. Join us
              in our fight against the climate crisis. Sign up today and be part
              of the change.
            </p>
          </div>
          <div className="flex mt-[20px] md:mb-0 mb-[64px]">
            <DrawOutlineButton5
              className="bg-[#1E3A2B] md:h-[45px] md:w-[50%] text-white px-6 py-3 rounded-full flex items-center justify-center"
              onClick={handleHref}
              name="Career"
            >
              <p className="transition-transform transform scale-105 group-hover:scale-100 font-medium">
                Careers
              </p>
            </DrawOutlineButton5>
            <DrawOutlineButton4
              className="bg-white ml-2 md:h-[45px] md:w-[50%] text-[#1E3A2B] px-6 py-3 rounded-full mr-4 flex items-center justify-center"
              onClick={handleHref}
              name="Signup"
            >
              <p className="transition-transform transform scale-105 group-hover:scale-100 font-medium">
                Join Us
              </p>
            </DrawOutlineButton4>
          </div>
        </div>
      </div>

      <div
        className="relative top-[65px] md:mt-[82px] text-center mx-auto md:w-[533px] md:h-[60px] text-[50px] font-extrabold font-[Gilroy-XBold]"
        id="contact"
      >
        <p>CONTACT</p>
      </div>

      <div className="mt-[80px] mx-auto md:w-[80%] md:flex block">
        <div className="md:h-[503px] md:w-[70%] w-[90%] md:mx-[52px] mx-auto">
          <div className="flex-col mx-auto md:w-[80%] mt-[20px]  md:h-[124px]  md:text-[30px] text-[20px] text-center flex items-center justify-center leading-[140.63%]">
            <p className="font-[Gilroy-Light] font-extralight"> Any doubts? </p>
            <p className="font-[Gilroy-Light] font-extralight">
              feel free to get in touch with us
            </p>
          </div>

          <div className="w-[85%] h-[290px] mt-[49px] mx-auto">
            <ContactForm />
          </div>
        </div>

        <div className="md:flex hidden h-[391px] border-[1px] border-solid border-black my-[57px] ml-[56px]"></div>

        <div className="md:block hidden mr-[13px] md:w-[30%] w-[80%] md:ml-[106px] mx-auto my-[46px]">
          <div className="mt-[10px] w-[80%] md:h-[100px] mx-auto font-[Gilroy-XBold] text-[28px] leading-[1.2em] text-center">
            CONTACT US
            <div className="mt-[23px] text-left text-[22px] ml-[30px] font-[Belleza]">
              <a
                href="mailto:labsmaiti@gmail.com"
                className="hover:text-[#1E3A2B]"
              >
                <IoMdMail className="inline mr-2" /> Mail
              </a>
            </div>
          </div>
          <div className="mt-[23px] w-[80%] mx-auto font-[Gilroy-XBold] text-[28px] leading-[1.2em] text-center">
            FOLLOW US
            <div className="mt-[24px] md:text-[20px] text-[18px] font-[Belleza] text-left ml-[30px]">
              <ul>
                <li>
                  {" "}
                  <a
                    className="block mt-[24px]  hover:text-[#1E3A2B]  "
                    href="https://www.instagram.com/maitilabs?igsh=MWF0c2J3eXdvNmFhMQ=="
                  >
                    <RxInstagramLogo className="inline mr-2" />
                    Instagram
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    className="block mt-[24px] hover:text-[#1E3A2B]"
                    href="https://www.linkedin.com/company/maitilabs/"
                  >
                    <FaLinkedin className="inline mr-2" />
                    Linkedin
                  </a>
                </li>
                <li>
                  <a
                    className="block mt-[24px]  hover:text-[#1E3A2B]"
                    href="https://x.com/MaitiLabs"
                  >
                    <FaTwitter className="inline mr-2" />
                    Twitter
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="relative mt-[150px] ">
        {" "}
        <video autoPlay loop muted loading="lazy">
          <source src={birdvideo} type="video/mp4" />{" "}
        </video>
      </div>
    </div>
    </>
  );
}

const DrawOutlineButton = ({ children, ...rest }) => {
  return (
    <button
      {...rest}
      className="group relative font-medium cursor-pointer w-[230px] my-[32px] h-[48px] p-1 delay-50 rounded-full bg-[#1e3a2b] hover:bg-slate-100/50 hover:shadow-md hover:shadow-emerald-800/50 text-slate-100 transition-colors duration-[300ms] hover:text-[#1e3a2b] hover:font-semibold transform scale-100 group-hover:scale-110"
    >
      <span>{children}</span>
    </button>
  );
};

const DrawOutlineButton2 = ({ children, ...rest }) => {
  return (
    <button
      {...rest}
      className="group relative font-medium cursor-pointer w-[230px] my-[32px] h-[48px] p-1 delay-50 rounded-full bg-[#1e3a2b] hover:bg-slate-100/50 hover:shadow-md hover:shadow-emerald-800/50 text-slate-100 transition-colors duration-[300ms] hover:text-[#1e3a2b] hover:font-semibold transform scale-100 group-hover:scale-110"
    >
      <span>{children}</span>
    </button>
  );
};

const DrawOutlineButton3 = ({ children, ...rest }) => {
  return (
    <button
      {...rest}
      className="group relative font-medium cursor-pointer w-[130px] my-[32px] h-[40px] delay-50 rounded-full bg-[#1e3a2b] hover:bg-slate-100/50 hover:shadow-md hover:shadow-emerald-800/50 text-slate-100 transition-colors duration-[300ms] hover:text-[#1e3a2b] hover:font-semibold transform scale-100 group-hover:scale-110"
    >
      <span>{children}</span>
    </button>
  );
};

const DrawOutlineButton4 = ({ children, ...rest }) => {
  return (
    <button
      {...rest}
      className="group relative font-medium cursor-pointer ml-1 w-[100px] h-[40px] delay-50 rounded-full bg-[#ffff] hover:bg-[#1e3a2b] hover:shadow-md hover:shadow-emerald-800/50 text-[#1e3a2b] transition-colors duration-[300ms] hover:text-[#ffff] hover:font-semibold transform scale-100 group-hover:scale-110"
    >
      <span>{children}</span>
    </button>
  );
};

const DrawOutlineButton5 = ({ children, ...rest }) => {
  return (
    <button
      {...rest}
      className="group relative font-mediumcursor-pointer mr-1 w-[100px] h-[40px] delay-50 rounded-full bg-[#1e3a2b] hover:bg-[#ffff] hover:shadow-md hover:shadow-emerald-800/50 text-[#ffff] transition-colors duration-[300ms] hover:text-[#1e3a2b] hover:bg-[#1e3a2b] hover:font-semibold transform scale-100 group-hover:scale-110"
    >
      <span>{children}</span>
    </button>
  );
};

const DrawOutlineButton6 = ({ children, ...rest }) => {
  return (
    <button
      {...rest}
      className="group relative font-mediumcursor-pointer w-[210px] h-[40px] delay-50 rounded-full bg-[#1e3a2b] hover:bg-[#ffff] hover:shadow-md hover:shadow-emerald-800/50 text-[#ffff] transition-colors duration-[300ms] hover:text-[#1e3a2b] hover:bg-[#1e3a2b] hover:font-semibold transform scale-100 group-hover:scale-110"
    >
      <span>{children}</span>
    </button>
  );
};

export default HomePage;
