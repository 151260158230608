import React from "react";
import Logo from "../assets/logo/Logo.png";
import { RxInstagramLogo } from "react-icons/rx";
import { FaLinkedin } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { useNavigate, useLocation } from "react-router-dom";

const url =
  "https://www.google.com/url?q=https%3A%2F%2Fchat.whatsapp.com%2FHToo6tS5XFO0bxbcOQTdy7&sa=D&sntz=1&usg=AOvVaw2jP6yJms6M04eeE0NaLo1P";

export default function Footer() {
  const navigate = useNavigate();
  function handleHref(e) {
    const Name = e.currentTarget.name;
    navigate("/" + Name);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  const location = useLocation();
  const path = location.pathname;
  const excludeHeader = ["/Vriksh", "/DeCarbonn"];
  if (excludeHeader.includes(path)) {
    return <div />;
  }
  return (
    <footer className="relative top-[82px] h-fit w-auto md:flex ">
      <div className="mx-auto md:mt-[68px] md:h-fit md:w-[50%] w-[100%]">
        <div className="flex md:ml-0 ml-10 h-[70px] font-[Gilroy-Light] font-light text-[32px] ">
          <img
            className="md:w-[68px] w-[48px] md:h-[68px] h-[48px]"
            src={Logo}
            alt="logo"
          ></img>{" "}
          <div className="pl-4 md:pt-2 font-bold">Maiti Labs</div>
        </div>
        <div className="md:hidden block my-[20px] font-[Gilroy-Light] font-light md:text-[25px] text-[22px] md:h-auto  h-[151px] leading-[29px] md:w-[415px] w-[80%] mx-auto ">
          <p className=" text-center ">
            Join a diverse and passionate community, making a lasting impact on
            our planet. Together, let's raise awareness and accelerate climate
            action worldwide.
          </p>
        </div>
        <div className="md:block hidden  my-[66px] font-[Gilroy-Light] font-light md:text-[25px] text-[22px] md:h-auto  h-[151px] leading-[29px] md:w-[415px]  ">
          <p className="md:justify-left  ">
            Join a diverse and passionate community, making a lasting impact on
            our planet. Together, let's raise awareness and accelerate climate
            action worldwide.
          </p>
        </div>
        <div className="flex md:w-full w-fit mx-auto mt-[80px]">
          <DrawOutlineButton
            className="mx-auto  rounded-[25px] bg-[#1E3A2B] text-white shadow-xl text-center px-3 py-2 focus:outline-none "
            onClick={() => window.open(url, "_blank")}
          >
            <p className="transition-transform transform scale-105 group-hover:scale-100 font-medium">
              Join Us
            </p>
          </DrawOutlineButton>
          <DrawOutlineButton2
            className="mx-auto  rounded-[25px] bg-[#1E3A2B] text-white shadow-xl text-center px-3  py-2 focus:outline-none "
            onClick={handleHref}
            name="Signup"
          >
            <p className="transition-transform transform scale-105 group-hover:scale-100 font-medium">
              Sign Up
            </p>
          </DrawOutlineButton2>
        </div>
      </div>

      <div className="md:flex hidden flex-col  md:w-[40%] mx-auto w-[90%] md:ml-[280px] my-[46px] mr-[46px] md:h-[470px] ">
        <DrawOutlineButton3
          className="mx-auto rounded-[25px] bg-[#1E3A2B] text-white shadow-xl text-center px-3  py-2 focus:outline-none "
          onClick={handleHref}
          name="Signup"
        >
          <p className="transition-transform transform scale-105 group-hover:scale-100 font-medium">
            Sign Up
          </p>
        </DrawOutlineButton3>
        <div className="flex mt-[80px]">
          <div className="mt-[23px] w-[40%]  font-[Plus Jakarta  Sans] font-bold text-[28px] leading-[24px]">
            Company
            <div className="mt-[19px] md:text-[20px] text-[18px] font-[Gilroy-Light] font-light text-left leading-[40px]">
              <ul>
                <li>
                  {" "}
                  <a className="block   hover:text-[#1E3A2B]  " href="/about">
                    About
                  </a>
                </li>
                <li>
                  {" "}
                  <a className="block  hover:text-[#1E3A2B]" href="/awareness">
                    Awareness Portal
                  </a>
                </li>
                <li>
                  <a className="block   hover:text-[#1E3A2B]" href="/Vriksh">
                    Vriksh
                  </a>
                </li>
                <li>
                  <a className="block   hover:text-[#1E3A2B]" href="/team">
                    Team
                  </a>
                </li>

                <li>
                  <a className="block  hover:text-[#1E3A2B]" href="/career">
                    Career
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-[23px] w-[40%]   font-[Plus Jakarta  Sans] font-bold  text-[28px] leading-[1.2em] ml-[85px]">
            Resources
            <div className="mt-[19px] md:text-[20px] text-[18px] font-[Gilroy-Light] font-light text-left leading-[40px]">
              <ul>
                <li>
                  {" "}
                  <a className="block  hover:text-[#1E3A2B]  " href="/AddBlog">
                    Blogs
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    className="block  hover:text-[#1E3A2B]"
                    href="https://www.linkedin.com/company/maitilabs/"
                  >
                    Linkedin
                  </a>
                </li>
                <li>
                  <a
                    className="block  hover:text-[#1E3A2B]"
                    href="https://x.com/MaitiLabs?t=t0BTUrSX61h-gBoopo700A&s=08"
                  >
                    Twitter
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* for mobile  */}
      <div>
        <div className="md:hidden flex mt-[48px] md:text-[20px] text-[18px] font-[Belleza] text-left bg-[#BCC2C4]">
          <ul className="flex h-fit w-[100%]  mt-[12px] mb-[20px] justify-around mx-[20px]">
            <li>
              <a
                className="  hover:text-[#1E3A2B]"
                href="mailto:labsmaiti@gmail.com"
              >
                <IoMdMail size={20} className="inline mr-2" />
              </a>
            </li>
            <li>
              {" "}
              <a
                className=" hover:text-[#1E3A2B]  "
                href="https://www.instagram.com/maitilabs?igsh=MWF0c2J3eXdvNmFhMQ=="
              >
                <RxInstagramLogo size={20} className="inline mr-2" />
              </a>
            </li>
            <li>
              {" "}
              <a
                className=" hover:text-[#1E3A2B]"
                href="https://www.linkedin.com/company/maitilabs/"
              >
                <FaLinkedin size={20} className="inline mr-2" />
              </a>
            </li>
            <li>
              <a className=" hover:text-[#1E3A2B]" href="/">
                <FaTwitter size={20} className="inline mr-2" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

const DrawOutlineButton = ({ children, ...rest }) => {
  return (
    <button
      {...rest}
      className="group relative font-mediumcursor-pointer mr-1 md:w-[150px] w-[100px] h-[40px] delay-50 rounded-full bg-[#1e3a2b] hover:bg-[#ffff] hover:shadow-md hover:shadow-emerald-800/50 text-[#ffff] transition-colors duration-[300ms] hover:text-[#1e3a2b] hover:bg-[#1e3a2b] hover:font-semibold transform scale-100 group-hover:scale-110"
    >
      <span>{children}</span>
    </button>
  );
};

const DrawOutlineButton2 = ({ children, ...rest }) => {
  return (
    <button
      {...rest}
      className="md:hidden group relative font-mediumcursor-pointer mr-1 md:w-[150px] w-[100px] h-[40px] delay-50 rounded-full bg-[#1e3a2b] hover:bg-[#ffff] hover:shadow-md hover:shadow-emerald-800/50 text-[#ffff] transition-colors duration-[300ms] hover:text-[#1e3a2b] hover:bg-[#1e3a2b] hover:font-semibold transform scale-100 group-hover:scale-110"
    >
      <span>{children}</span>
    </button>
  );
};

const DrawOutlineButton3 = ({ children, ...rest }) => {
  return (
    <button
      {...rest}
      className="group relative font-mediumcursor-pointer mr-1 md:w-[200px] w-[100px] h-[40px] delay-50 rounded-full bg-[#1e3a2b] hover:bg-[#ffff] hover:shadow-md hover:shadow-emerald-800/50 text-[#ffff] transition-colors duration-[300ms] hover:text-[#1e3a2b] hover:bg-[#1e3a2b] hover:font-semibold transform scale-100 group-hover:scale-110"
    >
      <span>{children}</span>
    </button>
  );
};
