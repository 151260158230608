import React from 'react';
import { Carousel } from 'react-responsive-carousel'; 
import 'react-responsive-carousel/lib/styles/carousel.min.css'; 
import elephant from "../../assets/images/elephant.png"; 
import delhi from "../../assets/images/delhi.png"; 
import bangalore from "../../assets/images/bangalore.png"; 
import ActiveProjects from './ActiveProjects';
import { CompletedProjects } from './CompletedProjects';
const ActivitiesCarousel = () => {
  return (
    <div className="text-center p-4 md:mx-4">
      <h2 className="text-2xl font-bold text-green-900">Activities</h2>
      <div className="relative mt-4">
        <Carousel
          showThumbs={false}
          showStatus={false}
          autoPlay
          infiniteLoop
          className="rounded-lg overflow-hidden font-[Gilroy-Bold]"
        >
          <div className="relative w-auto h-full md:h-[700px]"> {/* Fixed height */}
            <img src={elephant} alt="Forest for Elephant - Purulia, West Bengal" className="w-full h-full object-cover" />
            <p className="absolute bottom-0 left-0 w-full text-white text-sm md:text-lg p-4 bg-gradient-to-t from-black to-transparent">
              Forest for Elephant - Purulia, West Bengal
            </p>
          </div>
          <div className="relative w-auto h-full md:h-[700px]"> {/* Fixed height */}
            <img src={delhi} alt="Delhi Urban Forest - Juanapur, Delhi" className="w-full h-full object-cover" />
            <p className="absolute bottom-0 left-0 w-full text-white text-sm md:text-lg p-4 bg-gradient-to-t from-black to-transparent">
              Delhi Urban Forest - Juanapur, Delhi
            </p>
          </div>
          <div className="relative w-auto h-full md:h-[700px]"> {/* Fixed height */}
            <img src={bangalore} alt="Bangalore Urban Forest - Devarakere, Bangalore" className="w-full h-full object-cover" />
            <p className="absolute bottom-0 left-0 w-full text-white text-sm md:text-lg p-4 bg-gradient-to-t from-black to-transparent">
              Bangalore Urban Forest - Devarakere, Bangalore
            </p>
          </div>
        </Carousel>
        
        {/* Text Section: Full Width, Responsive, and Justified */}
        <div className="w-full p-4 text-justify text-sm md:text-lg font-bold text-gray-800 md:p-6">
          Planting trees is a simple action that everyone can take to reduce carbon dioxide, a principal greenhouse gas contributing to climate change. No matter where you live, you can plant trees and positively contribute to keeping our planet healthy.
        </div>
      </div>
      <ActiveProjects />
      <CompletedProjects/>  
    </div>
    
    
  );
};

export default ActivitiesCarousel;
