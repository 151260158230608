import { useEffect, useState } from "react";

function useScreenSize() {
    const [screenSize, setScreenSize] = useState({
      isMobile: window.innerWidth < 768,
      isTablet: window.innerWidth >= 768 && window.innerWidth < 1024,
      isDesktop: window.innerWidth >= 1024,
    });
  
    useEffect(() => {
      const handleResize = () => {
        setScreenSize({
          isMobile: window.innerWidth < 768,
          isTablet: window.innerWidth >= 768 && window.innerWidth < 1024,
          isDesktop: window.innerWidth >= 1024,
        });
      };
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return screenSize;
}

export default useScreenSize;