import React, { useEffect, useState } from 'react';
import { useAppContext } from '../appContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Dashboard = () => {
  const [user, setUser] = useState({});
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 9;

  const { authUser } = useAppContext();

  useEffect(() => {
    const storedUser = localStorage.getItem('authUser');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      fetchBlogsByPage(parsedUser.email, currentPage);
    }
  }, [currentPage]);

  const fetchBlogsByPage = async (email, page) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/getBlogs?email=${email}&page=${page}&limit=${blogsPerPage}`);
      const data = await response.json();
      setBlogs(data.blogs);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  const trimContent = (content, maxChars = 50) => {
    return content.length > maxChars ? content.substring(0, maxChars) + '...' : content;
  };

  const MyImage = ({ image }) => (
    <LazyLoadImage
      alt={image.alt}
      effect="blur"
      wrapperProps={{ style: { transitionDelay: '0.1s' } }}
      src={image.src}
    />
  );

  const renderBlogs = () => {
    return blogs.map((blog, index) => (
      <Link className="hover:text-inherit" to={`/blog/${blog._id}`} key={index}>
        <div className="bg-white p-4 rounded-lg shadow-md flex flex-col items-center">
          <div className="w-full h-48 bg-cover mb-4 overflow-hidden">
            <MyImage image={{ src: blog.image, alt: 'blogImage' }} />
          </div>
          <div className="w-full text-center">
            <div className="text-2xl font-[Gilroy-Bold] text-[#333333] mb-2">{blog.title}</div>
            <div
              className="text-sm text-gray-700"
              dangerouslySetInnerHTML={{ __html: trimContent(blog.content) }}
            ></div>
          </div>
        </div>
      </Link>
    ));
  };

  const renderSkeletonLoader = () => {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9].map((_, index) => (
      <div key={index} className="bg-white p-4 rounded-lg shadow-md flex flex-col items-center animate-pulse">
        <div className="w-full h-48 bg-gray-300 mb-4"></div>
        <div className="w-full text-center">
          <div className="h-4 bg-gray-300 mb-2 rounded w-3/4 mx-auto"></div>
          <div className="space-y-2">
            <div className="h-4 bg-gray-300 rounded w-5/6 mx-auto"></div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <header className="text-center mb-4">
        <h1 className="text-4xl font-[Gilroy-Bold]">User Dashboard</h1>
      </header>
      <div className="container mx-auto bg-white p-6 rounded-lg shadow-md">
        <div className="mb-6 flex justify-between items-start">
          <div className="text-left">
            <h2 className="text-2xl font-semibold  text-[#1e3a2b] mb-2">Hi {user.name}</h2>
            <h2 className="text-2xl font-semibold  text-[#1e3a2b] mb-2"> {user.email}</h2>

            
          </div>
        </div>
        <div className="mb-6">
          <h2 className="text-2xl font-[Gilroy-Bold]  text-[#1e3a2b] mb-2"> Your Blogs</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {blogs.length > 0 ? renderBlogs() : renderSkeletonLoader()}
          </div>
          <div className="flex justify-center mt-4">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              className={`mx-2 px-4 py-2 bg-[#1e3a2b] ${
                currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#397153] hover:cursor-pointer'
              } text-white rounded-lg`}
              disabled={currentPage === 1}
            >
              Previous Page
            </button>
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              className={`mx-2 px-4 py-2 bg-[#1e3a2b] ${
                blogs.length < blogsPerPage ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#397153] hover:cursor-pointer'
              } text-white rounded-lg`}
              disabled={blogs.length < blogsPerPage}
            >
              Next Page
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;