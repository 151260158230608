import React from "react";
import before from "../img/before_compression1.png";
import after from "../img/after_compression2.png";
import beforemain from "../img/before_cpmressioon_main.png";
import aftermain from "../img/after_cpmressioon_main.png";
import img2 from "../Images/cr7.jpg";
import img3 from "../Images/card-icon.png";
import GreenBitHeader from "./GreenBitHeader";
import GreenBitFooter from "./GreenBitFooter";
const GreenBit = () => {
  return (
    <>
      <GreenBitHeader />
      <div className="sm:p-[70px] p-[10px] bg-[#f8f4f0]">
        {/* first part */}
        <div className="px-4 sm:px-6 lg:px-8 sm:mt-20">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/2">
              <div className="w-full h-[30vh] mt-4 mb-20 md:hidden block">
                <div className="relative z-0">
                  <img
                    src={before}
                    alt="Before"
                    className="object-cover rounded w-1/2 absolute top-5 left-5 mt-1/2 ml-1/2 z-10"
                  />
                  <img
                    src={after}
                    alt="After"
                    className="object-cover rounded w-1/2 absolute top-24 right-5 mb-1/2 mr-1/2 z-10"
                  />
                </div>
              </div>
              <h1 className="font-bold text-3xl md:text-5xl mb-4 ">
                Compressing Data, Expanding Futures
              </h1>
              <p className="mb-4 text-justify mt-4">
                We're a climate-tech startup pioneering data compression tech to
                slash carbon footprints.
              </p>
            </div>
            <div className="md:w-1/2 md:pb-[200px] hidden md:block">
              <div className="w-full relative">
                <div className="absolute left-16 top-0  w-1/2 h-1/2">
                  <img src={before} alt="Before" className=" object-cover" />
                </div>
                <div class="absolute bottom-28 right-0 w-1/2 h-1/2 ">
                  <img src={after} alt="After" className=" object-cover" />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 md:mt-0 ">
            <a
              href="https://calendly.com/shryasbhurat"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="bg-[#90EE90] text-green-900 hover:bg-green-600 font-bold py-2 px-4  cursor-pointer rounded-lg mb-4">
                Get in Touch -&gt;
              </button>
            </a>
          </div>
        </div>

        {/* second part */}
        <div className="  text-justify md:pr-[35%] m-3 mb-5 ">
          <p className="md:mt-36 ">
            Leveraging AI-driven algorithms, our climate-tech startup pioneers
            data compression technology tailored to slash carbon footprints. By
            compressing carbon data, we make sustainability scalable, empowering
            organizations to efficiently manage and reduce their environmental
            impact.
          </p>
        </div>

        {/* cards */}

        <div className="mb-[100px] mt-[100px]">
          {" "}
          <div className="flex flex-wrap justify-center mt-5">
            <div className="w-full sm:w-1/2 md:w-full lg:w-1/3 xl:w-1/3 xl:px-4 mb-4">
              <div className="bg-[#f8f4f0] p-4">
                <img src={img3} alt="Icon" className="w-12 h-12 mb-2" />
                <h2 className="font-bold mb-2">Superior Compression Rates</h2>
                <hr className="mb-2" />
                <p className="text-gray-700 text-justify">
                  Our AI-driven compression technology achieves unmatched
                  efficiency, reducing carbon data by up to{" "}
                  <span className="font-bold">40%</span> compared to
                  conventional methods, facilitating streamlined data storage
                  and transmission for sustainable practices.
                </p>
              </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-full lg:w-1/3 xl:w-1/3 xl:px-4 mb-4">
              <div className="bg-[#f8f4f0] p-4">
                <img src={img3} alt="Icon" className="w-12 h-12 mb-2" />
                <h2 className="font-bold mb-2">Scalable Solutions</h2>
                <hr className="mb-2" />
                <p className="text-gray-700 text-justify">
                  Our platform prioritizes scalability offering adaptable
                  solutions suitable for businesses of any size. Our technology
                  seamlessly integrates into existing systems, empowering users
                  to effortlessly implement cutting-edge compression techniques
                  for carbon footprint reduction.
                </p>
              </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-full lg:w-1/3 xl:w-1/3 xl:px-4 mb-4">
              <div className="bg-[#f8f4f0] p-4">
                <img src={img3} alt="Icon" className="w-12 h-12 mb-2" />
                <h2 className="font-bold mb-2">Customized Solutions</h2>
                <hr className="mb-2" />
                <p className="text-gray-700 text-justify">
                  Recognizing the diverse needs of users, our AI compression
                  website provides tailored solutions. Users can customize
                  compression settings and algorithms to suit their specific
                  data requirements, ensuring optimized efficiency and
                  effectiveness in carbon data management.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center  mb-8">
          {/* Section heading */}
          <h2 className="text-3xl font-bold mb-2">
            Image Compression Comparison
          </h2>
          {/* Subheading */}
          <p className="text-lg text-gray-600 lg:w-1/2 w-full mx-auto ">
            Compare original and compressed images side by side, highlighting
            the efficiency and impact of our image compression technology
          </p>
        </div>
        {/* Images */}
        <div className="flex flex-col md:flex-row items-center justify-center mb-5 ">
          {/* Before Image */}
          <div className="relative mb-4 md:mb-0 md:mr-2">
            <div>
              <p className="text-center absolute top-0 right-0 mt-3 w-fit font-bold bg-opacity-50  backdrop-filter backdrop-blur-sm text-black py-1">
                Before
                <br />
                <span className="font-thin p-2"> 0.240bpp</span>
              </p>
            </div>
            <img
              src={beforemain}
              alt="Before"
              className="max-w-full md:max-w-xs"
            />
          </div>
          {/* After Image */}
          <div className="relative mb-4 md:mb-0 md:ml-2 ">
            <p className="text-center absolute top-0 right-0 sm:left-0 mt-3 w-fit bg-opacity-50  backdrop-filter backdrop-blur-sm text-black py-1 font-bold">
              After
              <br />
              <span className="font-thin p-2">0.140bpp</span>
            </p>
            <img
              src={aftermain}
              alt="After"
              className="max-w-full md:max-w-xs"
            />
          </div>
        </div>

        <h2 className="md:ml-[70px] ml-[20px] font-bold md:text-5xl  text-3xl mb-[50px]">
          Why Choose Compression?
        </h2>
        {/* compression section */}
        <div className="flex flex-col  xl:flex-row items-center xl:ml-14">
          <img
            src={img2}
            alt="CR7"
            className="md:w-[500px] w-[300px] h-[400px] md:h-[700px] md:ml-14 md:mr-20 mb-8 md:mb-8"
          />
          <div className="xl:ml-20 ">
            <div className="bg-[#f8f4f0] p-4 mb-4 md:mb-0 md:w-[600px] text-justify">
              <h2 className="font-bold mb-2">Data Center's Carbon Footprint</h2>
              <hr className="mb-2" />
              <p>
                Data centers are estimated to account for approximately{" "}
                <span className="font-bold">2%</span> of global greenhouse gas
                emission, a significant portion of which is attributed to energy
                consumption for data storage and processing.
              </p>
            </div>
            <div className="bg-[#f8f4f0] p-4 mb-4 md:mb-0 md:w-[600px] text-justify">
              <h2 className="font-bold mb-2">
                Energy Reduction through Compression
              </h2>
              <hr className="mb-2" />
              <p>
                Advanced ML-based data compression can significantly decrease
                the amount of data requiring storage and transmission,
                potentially reducing data center energy use by a notable margin.
              </p>
            </div>
            <div className="bg-[#f8f4f0] p-4 md:w-[600px] text-justify">
              <h2 className="font-bold mb-2">CO2 Emissions Savings</h2>
              <hr className="mb-2" />
              <p>
                Implementing more efficient data compression algorithms could
                save millions of tons of CO2 emissions annually by reducing the
                energy demand of data centers and contributing to a more
                sustainable digital ecosystem.
              </p>
            </div>
          </div>
        </div>

        <div className="mt-32 md:flex justify-between">
          <div className="w-full md:w-1/2 flex flex-col justify-center items-center md:items-start">
            <h1 className="font-bold text-3xl md:text-4xl mb-4 md:mb-2 text-center">
              Be a part of the Compression Revolution
            </h1>
          </div>
          <div className="w-full md:w-1/2 flex flex-col items-center">
            <p className=" mb-2 md:mb-4 text-lg text-center">
              Partner with us to integrate our innovative data compression
              solutions and amplify your sustainability efforts.
            </p>
            <a
              href="https://calendly.com/shryasbhurat"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="bg-[#90EE90] text-green-900 hover:bg-green-600 font-bold py-2 px-4 mt-2 mb-5 rounded cursor-pointer">
                Get in Touch -&gt;
              </button>
            </a>
          </div>
        </div>

        {/* last part */}
      </div>
      <GreenBitFooter />
    </>
  );
};

export default GreenBit;
