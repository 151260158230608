import React from "react";
import { ContactForm } from "./ContactForm";
import { Helmet } from "react-helmet";
export default function Contact() {
  return (
    <div>
      <Helmet>
        <title>Maiti Labs - Contact Us</title>
        <meta
          name="description"
          content="Get in touch with Maiti Labs, a global youth-led Non-Profit Organization focused on combating climate change. Reach out to us for collaborations, inquiries, and more."
        />
        <link rel="canonical" href="https://www.maitilabs.org/contact" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Contact Us",
              "url": "https://www.maitilabs.org/contact",
              "description": "Get in touch with Maiti Labs, a global youth-led Non-Profit Organization focused on combating climate change. Reach out to us for collaborations, inquiries, and more.",
              "mainEntity": {
                "@type": "Organization",
                "name": "Maiti Labs"
              }
            }
          `}
        </script>
      </Helmet>
      <div className="container-fluid bg-image">
        <div className="row">
          <div className="col-12 centered-text">
            <h1
              style={{
                marginTop: "200px",
                fontSize: "85px",
                lineHeight: "85px",
              }}
            >
              Contact
            </h1>
          </div>
        </div>
      </div>
      <div className="w-[75%] md:w-[60%] h-[290px] mt-[49px] mx-auto">
        <ContactForm />
      </div>
    </div>
  );
}
