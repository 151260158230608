/* Uncomment Below once payment gateway is added */
// import tick from "../assets/tick.svg";
// import cross from "../assets/cross.svg";
import useStore from "../store";
import { useEffect, useRef } from "react";
import { ContactForm } from "../ContactForm";

export const Subs = () => {
  const setRef = useStore((state) => state.setRef);
  const Ref = useRef(null);
  useEffect(() => {
    setRef(Ref.current);
  });

  return (
    <>
      <div id="contact" className="mt-12 mb-28 md:mb-32 md:mt-22" ref={Ref}>
        <div className="text-center md:text-center font-jostSemiBold text-2xl md:text-4xl my-10 md:mt-24">
          <h3>Play your part in saving the environment</h3>
          <br/>
          <div className="flex-col mx-auto md:w-[80%] md:text-[30px] text-[20px] text-center leading-[140.63%]">
            <h4 className="font-[Gilroy-Light] font-extralight">
              Partner with Us to Save the Environment
            </h4>
          </div>
        </div>

        {/* Uncomment Below once payment gateway is added */}

        {/* <div className="relative flex flex-wrap gap-6 justify-evenly">
          <div className=" bg-custom-orange  rounded-3xl p-[32px] md:p-[48px]">
            <div className="my-[8px] md:my-[12px] mr-12 md:mr-20">
              <h1 className="text-3xl md:text-4xl font-jostSemiBold">Basic</h1>
              <p className="text-xs md:text-sm font-jostRegular">
                For personal donations, one time
              </p>
            </div>
            <div className="flex my-[40px] mr-20">
              <h1 className="text-3xl md:text-4xl font-jostSemiBold">$1</h1>
              <p className="text-xs md:text-sm font-jostRegular ml-[10px] md:ml-[15px] mt-[15px]">
                Per tree , one time payment
              </p>
            </div>
            <div className="flex flex-col items-center">
              <DrawOutlineButton>
              <p className="transition-transform transform scale-105 group-hover:scale-100">
              Plant Now
              </p>
              </DrawOutlineButton>
            </div>
            <div>
              <ul className="mt-6 md:mt-10">
                <li className="flex items-center text-lg md:text-xl font-jostSemiBold">
                  <img
                    src={tick}
                    alt="tick"
                    className="mr-[16px] w-[15px] h-[10px] md:w-[20] md:h-[15px]"
                  />
                  One time purchase
                </li>
                <li className="flex items-center text-lg md:text-xl font-jostSemiBold mt-[8px] md:mt-[16px]">
                  <img
                    src={tick}
                    alt="tick"
                    className="mr-[16px] w-[15px] h-[10px] md:w-[20] md:h-[15px]"
                  />
                  One dollar per tree
                </li>
                <li className="flex items-center text-lg md:text-xl font-jostSemiBold  mt-[8px] md:mt-[16px]">
                  <img
                    src={cross}
                    alt="cross"
                    className="mr-[16px] w-[15px] h-[10px] md:w-[20] md:h-[15px]"
                  />
                  doesnt calculate your offset
                </li>
              </ul>
            </div>
          </div>
          <div className=" bg-custom-orange rounded-3xl p-[32px] md:p-[48px]">
            <div className="my-[8px] md:my-[12px]">
              <h1 className="text-3xl md:text-4xl font-jostSemiBold">
                Advanced
              </h1>
              <p className="text-xs md:text-sm font-jostRegular">
                For companies to maintain their carbon footprints
              </p>
            </div>
            <div className="flex my-[40px] mr-[28px]">
              <h1 className="text-3xl md:text-4xl font-jostSemiBold">$5</h1>
              <p className="text-xs md:text-sm font-jostRegular ml-[10px] md:ml-[15px] mt-[15px]">
                Per month + addons ,billed monthly
              </p>
            </div>
            <div className="flex flex-col items-center">
              <DrawOutlineButton2>
              <p className="transition-transform transform scale-105 group-hover:scale-100">
              Plant Now
            </p>
              </DrawOutlineButton2>
            </div>
            <div>
              <ul className="mt-6 md:mt-10">
                <li className="flex items-center text-lg md:text-xl font-jostSemiBold">
                  <img
                    src={tick}
                    alt="tick"
                    className="mr-4 w-[15px] h-[10px] md:w-[20] md:h-[15px]"
                  />
                  monthly subscribtion
                </li>
                <li className="flex items-center text-lg md:text-xl font-jostSemiBold mt-[8px] md:mt-4">
                  <img
                    src={tick}
                    alt="tick"
                    className="mr-4 w-[15px] h-[10px] md:w-[20] md:h-[15px]"
                  />
                  starting at 5 dollars
                </li>
                <li className="flex items-center text-lg md:text-xl font-jostSemiBold mt-[8px] md:mt-4">
                  <img
                    src={tick}
                    alt="tick"
                    className="mr-4 w-[15px] h-[10px] md:w-[20] md:h-[15px]"
                  />
                  calculate your offset
                </li>
              </ul>
            </div>
          </div>
        </div> */}
        {/* Remove Below once payment gateway is added */}
        <div className="w-[85%] md:w-1/2 mt-8 mx-auto">
          <ContactForm partner/>
        </div>
      </div>
    </>
  );
};

//  Uncomment Below once payment gateway is added 

// const DrawOutlineButton = ({ children, ...rest }) => {
//   return (
//     <button
//       {...rest}
//       className="group relative bg-[#fff] text-custom-green4 text-2xl md:text-3xl rounded-xl font-poppinsBold py-[4px] px-12 md:px-16 hover:shadow-md hover:shadow-emerald-800/50 transition-colors duration-[300ms] hover:text-[#fff] hover:bg-[#1e3a2b] hover:font-semibold transform scale-100 group-hover:scale-110"
//     >
//       <span>{children}</span>
//     </button>
//   );
// };

// const DrawOutlineButton2 = ({ children, ...rest }) => {
//   return (
//     <button
//       {...rest}
//       className="group relative bg-custom-green4 text-[#fff] text-2xl md:text-3xl rounded-xl font-poppinsBold py-[4px] px-12 md:px-16 hover:bg-slate-100/50 hover:shadow-md hover:shadow-emerald-800/50 transition-colors duration-[300ms] hover:text-[#1e3a2b] hover:font-semibold transform scale-100 group-hover:scale-110"
//     >
//       <span>{children}</span>
//     </button>
//   );
// };