import React, { useState } from "react";

const faqs = [
  {
    question: "How can planting trees combat climate change?",
    answer: "Trees absorb carbon dioxide, a major greenhouse gas, during photosynthesis and store carbon in their biomass. By planting more trees, we can reduce the amount of CO2 in the atmosphere, helping to mitigate the effects of climate change.",
  },
  {
    question: "What are some benefits of urban green spaces?",
    answer: "Urban green spaces, such as parks and community gardens, provide numerous benefits including reducing the urban heat island effect, improving air and water quality, promoting mental and physical health, and enhancing community well-being.",
  },
  {
    question: "What are some sustainable practices for managing forests?",
    answer: "Sustainable forestry practices include selective logging, replanting native species, maintaining buffer zones, protecting old-growth forests, and ensuring that forest management practices do not harm the ecosystem.",
  },
  {
    question: "What are the environmental benefits of using native plants in landscaping?",
    answer: "Native plants are well-adapted to local conditions, requiring less water, fertilizers, and pesticides. They support local wildlife, including pollinators and birds, enhance soil health, and help preserve the natural heritage of an area.",
  },
  {
    question: "How can I get involved in local tree-planting initiatives?",
    answer: "You can get involved by joining local environmental organizations, participating in community tree-planting events, volunteering with conservation groups, or starting your own tree-planting project in your neighborhood.",
  }
];

export default function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="container mx-auto mt-16 mb-15">
      <div className="w-[80%] mx-auto mt-[57px]">
        <div className="font-[Gilroy-Bold] text-[60px] text-[#333333] leading-[1.2em] tracking-[-0.04em] text-center">
          FAQ
        </div>
        <div className="w-[100%] mt-[34px] font-[Belleza] font-light text-[#1E3A2B] text-[27px] leading-[1.2em] text-center">
          Frequently Asked Questions about Climate Change and Sustainability
        </div>
      </div>
      <div className="w-[80%] mx-auto mt-[34px]">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="mb-4 p-4 rounded-lg bg-gray-100 hover:bg-gray-200 transition-colors duration-300 cursor-pointer"
            onClick={() => toggleFAQ(index)}
          >
            <div className="font-[Gilroy-Light] text-[28px] leading-[1.4em] text-[#333333]">
              {faq.question}
            </div>
            {activeIndex === index && (
              <div className="mt-2 text-[20px] text-[#1E3A2B]">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
