import React, { useState, useEffect } from "react";
import img1 from "../img/shryas-removebg-preview.png";
import img2 from "../img/ashish_yadav-removebg-preview.png";
import { teamMembers } from "../data";
import { FaLinkedin, FaTwitter } from "react-icons/fa";
import { Helmet } from "react-helmet";

const url =
  "https://www.google.com/url?q=https%3A%2F%2Fchat.whatsapp.com%2FHToo6tS5XFO0bxbcOQTdy7&sa=D&sntz=1&usg=AOvVaw2jP6yJms6M04eeE0NaLo1P";

const Team = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);
  
  const [accordionOpen, setAccordionOpen] = useState(null);

  const handleAccordionToggle = (index) => {
    setAccordionOpen((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
    <Helmet>
        <title>Maiti Labs - Team</title>
        <meta name="description" content="Meet the dynamic team of Maiti Labs who are driving innovation and making a lasting impact on our planet." />
        <link rel="canonical" href="https://www.maitilabs.org/team" />
        <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Team",
            "url": "https://www.maitilabs.org/team",
            "description": "Meet the dynamic team of Maiti Labs who are driving innovation and making a lasting impact on our planet.",
            "mainEntity": {
              "@type": "Organization",
              "name": "Maiti Labs",
            }
          }
        `}
        </script>
      </Helmet>
      {/* first part */}
      <div className="relative mt-4">
        {/* Green strip for desktop view */}
        <div className="absolute inset-x-0 top-20 h-[210px] bg-[#1c4532]"></div>

        <div className="relative z-9">
          <div className="flex items-center justify-center">
            <h1 className="text-2xl text-[#204228] tracking-widest">
              LEADERSHIP
            </h1>
          </div>
          <div className="flex place-content-evenly flex-wrap">
            {/* first image div*/}
            <div className="flex flex-col items-center mb-10">
              <img alt="CEO" src={img1} className="w-[300px] h-auto " />
              <div>
                <h3 className="text-center">Shryas Bhurat</h3>
                <p className="text-justify">Co-Founder and CEO</p>
                {/* LinkedIn and Twitter buttons */}
                <div className="flex mt-2 justify-center align-center">
                  <a
                    href="https://www.linkedin.com/in/shryasbhurat"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin size={24} className="mr-2" />
                  </a>
                  <a
                    href="https://x.com/shryasbhurat"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter size={24} />
                  </a>
                </div>
              </div>
              {/* Green strip for mobile view */}
              <div className="md:hidden mt-[32px] absolute inset-x-0 top-[430px] h-[215px] bg-[#1c4532] z-5"></div>
            </div>
            {/* 2nd image div */}
            <div className="flex flex-col items-center relative z-1">
              {" "}
              <img alt="COO" src={img2} className="w-[300px] h-auto " />
              <div>
                <h3 className="text-center">Aashish Yadav</h3>
                <p className="text-justify">Co-Founder and COO</p>
                {/* LinkedIn and Twitter buttons */}
                <div className="flex mt-2 justify-center align-center">
                  <a
                    href="https://www.linkedin.com/in/x0aashish"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin size={24} className="mr-2" />
                  </a>
                  <a
                    href="https://x.com/x0aashish"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter size={24} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 2nd part - Accordion */}
      <div className="mt-[60px]">
        <div className="flex items-center justify-center">
          <h2 className="font-bold text-3xl text-center tracking-widest mx-auto  text-[#204228] ">
            Meet Our Dynamic team
          </h2>
        </div>
        <div className="flex items-center justify-center ">
          <div className=" max-w-md ">
            {teamMembers.map((team, index) => (
              <div key={index} className="text-center ">
                <button
                  className="p-2 m-8 text-2xl text-justify text-[#204228] "
                  onClick={() => handleAccordionToggle(index)}
                >
                  {team.name}
                </button>
                <hr className="w-full mx-auto" />{" "}
                {accordionOpen === index && (
                  <div className="p-2 text-left">
                    <ul className="ml-10 list-disc">
                      {team.members.map((member, i) => (
                        <li key={i} className="text-xl m-2 text-[#204228]">
                          {member}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* third part */}
      <div className="flex flex-col md:flex-row items-center justify-center md:place-content-evenly m-[60px]">
        <div className="md:my-0 my-6 font-[Gilroy-Light] font-light text-[22px] leading-[29px] md:w-[415px]">
          <p className="text-center md:text-left">
            Want to join a diverse and passionate community, making a lasting
            impact on our planet. Together?
          </p>
        </div>
        <button
          className="sm:w-full md:w-auto group relative px-4  cursor-pointer h-[40px] delay-75 bg-[#1e3a2b] hover:bg-slate-100/50 hover:shadow-md hover:shadow-emerald-800/50 text-slate-100 transition-colors duration-[300ms] hover:text-[#1e3a2b] font-medium rounded-[25px] mt-4 md:mt-0"
          onClick={() => window.open(url, "_blank")}
        >
          <span>
            <div className="transition ease-in-out  delay-50 hover:-translate-y-1 hover:scale-105 mx-3 my-2 cursor-pointer hover:text-[#496c62]">
              Join Us
            </div>
          </span>
        </button>
      </div>
    </>
  );
};

export default Team;
